/* eslint-disable: interface-over-type-literal */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
	  };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
	DateTime: { input: any; output: any };
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: { input: any; output: any };
};

/** The theme of the course session */
export enum CourseSessionTheme {
	Body = 'Body',
	Other = 'Other',
	SocialRelations = 'SocialRelations',
	Strategies = 'Strategies',
	TheFocusYouWant = 'TheFocusYouWant',
	ThoughtsAndFeelings = 'ThoughtsAndFeelings',
	Unknown = 'Unknown',
}

/** The type of the course session */
export enum CourseSessionType {
	Regular = 'Regular',
	ThemeIntroduction = 'ThemeIntroduction',
}

/** The status of the course enrolment */
export enum EnrolmentStatus {
	Cancelled = 'Cancelled',
	Enrolled = 'Enrolled',
	Expired = 'Expired',
	Ongoing = 'Ongoing',
}

/** Resource representing a course */
export type FmsCourse = IFmsCourse & {
	__typename?: 'FmsCourse';
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	enrolments: Array<Maybe<FmsEnrolment>>;
	id: Scalars['ID']['output'];
	imageUrl?: Maybe<Scalars['String']['output']>;
	sessions?: Maybe<Array<Maybe<FmsCourseSession>>>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	version?: Maybe<Scalars['Int']['output']>;
};

/** Resource representing a course session */
export type FmsCourseSession = IFmsCourseSession & {
	__typename?: 'FmsCourseSession';
	componentByType?: Maybe<FmsSessionComponent>;
	components: Array<Maybe<FmsSessionComponent>>;
	course?: Maybe<FmsCourseSession>;
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	nextSession?: Maybe<FmsCourseSession>;
	prevSession?: Maybe<FmsCourseSession>;
	theme: CourseSessionTheme;
	themeNumber?: Maybe<Scalars['Float']['output']>;
	themeSessionNumber: Scalars['Int']['output'];
	title?: Maybe<Scalars['String']['output']>;
	type: CourseSessionType;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	version?: Maybe<Scalars['Int']['output']>;
};

/** Resource representing a course session */
export type FmsCourseSessionComponentByTypeArgs = {
	componentType?: InputMaybe<SessionComponentType>;
};

/** Resource representing a course enrolment by a user */
export type FmsEnrolment = IFmsEnrolment & {
	__typename?: 'FmsEnrolment';
	activeTheme: CourseSessionTheme;
	confirmCorrectInfo: Scalars['Boolean']['output'];
	consentToInfoSharedWithLicenseHolder: Scalars['Boolean']['output'];
	consentToResearchEvaluationContact: Scalars['Boolean']['output'];
	consentToSms: Scalars['Boolean']['output'];
	course: FmsCourse;
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	isActive: Scalars['Boolean']['output'];
	licenseKey: Scalars['String']['output'];
	onboardingCompleted: Scalars['Boolean']['output'];
	organizationId: Scalars['String']['output'];
	organizationType: FmsOrganizationType;
	startDate: Scalars['DateTime']['output'];
	status: EnrolmentStatus;
	stopDate?: Maybe<Scalars['DateTime']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	user: FmsUser;
	version?: Maybe<Scalars['Int']['output']>;
};

/** Resource representing a media element */
export type FmsMediaElement = IFmsMediaElement & {
	__typename?: 'FmsMediaElement';
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	duration?: Maybe<Scalars['Float']['output']>;
	id: Scalars['ID']['output'];
	mediaType: MediaType;
	relatedComponents: Array<Maybe<FmsSessionComponent>>;
	srcId?: Maybe<Scalars['String']['output']>;
	srcProvider: FmsMediaProvider;
	srcUrl?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	version?: Maybe<Scalars['Int']['output']>;
};

/** The media providers used */
export enum FmsMediaProvider {
	S3 = 'S3',
	Unknown = 'Unknown',
	Vimeo = 'Vimeo',
}

/** The basic organization types */
export enum FmsOrganizationType {
	Business = 'Business',
	Municipality = 'Municipality',
}

/** Resource representing a session component */
export type FmsSessionComponent = IFmsSessionComponent & {
	__typename?: 'FmsSessionComponent';
	aboveFoldContent?: Maybe<Scalars['String']['output']>;
	belowFoldContent?: Maybe<Scalars['String']['output']>;
	componentType: SessionComponentType;
	courseSession: FmsCourseSession;
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	/** Get favorite action for component if available. If no userId is provided, check is for logged-in user */
	favoriteAction?: Maybe<FmsUserAction>;
	id: Scalars['ID']['output'];
	legacyUrl?: Maybe<Scalars['String']['output']>;
	mediaElement?: Maybe<FmsMediaElement>;
	quizSpecification?: Maybe<Scalars['JSON']['output']>;
	/** Get seen action for component if available. If no userId is provided, check is for logged-in user */
	seenAction?: Maybe<FmsUserAction>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	userActions?: Maybe<Array<FmsUserAction>>;
	version?: Maybe<Scalars['Int']['output']>;
};

/** Resource representing a session component */
export type FmsSessionComponentFavoriteActionArgs = {
	userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Resource representing a session component */
export type FmsSessionComponentSeenActionArgs = {
	userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Object representing an user */
export type FmsUser = IFmsUser & {
	__typename?: 'FmsUser';
	actions?: Maybe<Array<FmsUserAction>>;
	address1?: Maybe<Scalars['String']['output']>;
	address2?: Maybe<Scalars['String']['output']>;
	auth0Sub?: Maybe<Scalars['String']['output']>;
	city?: Maybe<Scalars['String']['output']>;
	consentGeneral?: Maybe<Scalars['Boolean']['output']>;
	createdAt: Scalars['DateTime']['output'];
	createdByUser?: Maybe<FmsUser>;
	email: Scalars['String']['output'];
	emailVerified: Scalars['Boolean']['output'];
	enrolments?: Maybe<Array<FmsEnrolment>>;
	firstName?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	lastName?: Maybe<Scalars['String']['output']>;
	phone?: Maybe<Scalars['String']['output']>;
	postalCode?: Maybe<Scalars['Float']['output']>;
	smsConsent?: Maybe<Scalars['Boolean']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	version?: Maybe<Scalars['Int']['output']>;
};

/** Resource representing a media element */
export type FmsUserAction = IFmsUserAction & {
	__typename?: 'FmsUserAction';
	actionType: UserActionType;
	component: FmsSessionComponent;
	createdAt: Scalars['DateTime']['output'];
	createdByUser: FmsUser;
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	updatedByUser?: Maybe<FmsUser>;
	user: FmsUser;
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsCourse = {
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	enrolments: Array<Maybe<FmsEnrolment>>;
	id: Scalars['ID']['output'];
	imageUrl?: Maybe<Scalars['String']['output']>;
	sessions?: Maybe<Array<Maybe<FmsCourseSession>>>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsCourseSession = {
	components: Array<Maybe<FmsSessionComponent>>;
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	theme: CourseSessionTheme;
	title?: Maybe<Scalars['String']['output']>;
	type: CourseSessionType;
	updatedAt: Scalars['DateTime']['output'];
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsEnrolment = {
	activeTheme: CourseSessionTheme;
	course?: Maybe<FmsCourse>;
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	onboardingCompleted: Scalars['Boolean']['output'];
	startDate: Scalars['DateTime']['output'];
	status: EnrolmentStatus;
	stopDate?: Maybe<Scalars['DateTime']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	user?: Maybe<FmsUser>;
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsMediaElement = {
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	duration?: Maybe<Scalars['Float']['output']>;
	id: Scalars['ID']['output'];
	mediaType: MediaType;
	relatedComponents: Array<Maybe<FmsSessionComponent>>;
	srcUrl?: Maybe<Scalars['String']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsSessionComponent = {
	aboveFoldContent?: Maybe<Scalars['String']['output']>;
	belowFoldContent?: Maybe<Scalars['String']['output']>;
	componentType: SessionComponentType;
	courseSession: FmsCourseSession;
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	legacyUrl?: Maybe<Scalars['String']['output']>;
	mediaElement?: Maybe<FmsMediaElement>;
	quizSpecification?: Maybe<Scalars['JSON']['output']>;
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	userActions?: Maybe<Array<FmsUserAction>>;
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsUser = {
	actions?: Maybe<Array<FmsUserAction>>;
	address1?: Maybe<Scalars['String']['output']>;
	address2?: Maybe<Scalars['String']['output']>;
	auth0Sub?: Maybe<Scalars['String']['output']>;
	city?: Maybe<Scalars['String']['output']>;
	consentGeneral?: Maybe<Scalars['Boolean']['output']>;
	createdAt: Scalars['DateTime']['output'];
	email: Scalars['String']['output'];
	emailVerified: Scalars['Boolean']['output'];
	enrolments?: Maybe<Array<FmsEnrolment>>;
	firstName?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	lastName?: Maybe<Scalars['String']['output']>;
	phone?: Maybe<Scalars['String']['output']>;
	postalCode?: Maybe<Scalars['Float']['output']>;
	smsConsent?: Maybe<Scalars['Boolean']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	version?: Maybe<Scalars['Int']['output']>;
};

export type IFmsUserAction = {
	actionType: UserActionType;
	component: FmsSessionComponent;
	createdAt: Scalars['DateTime']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	title?: Maybe<Scalars['String']['output']>;
	updatedAt: Scalars['DateTime']['output'];
	user: FmsUser;
	version?: Maybe<Scalars['Int']['output']>;
};

/** The basic media types */
export enum MediaType {
	Audio = 'Audio',
	Document = 'Document',
	Image = 'Image',
	Other = 'Other',
	Unknown = 'Unknown',
	Video = 'Video',
}

export type Mutation = {
	__typename?: 'Mutation';
	createCourse: FmsCourse;
	createCourseSession: FmsCourseSession;
	createEnrolment: FmsEnrolment;
	createMediaElement: FmsMediaElement;
	createSessionComponent: FmsSessionComponent;
	createUser: FmsUser;
	createUserAction: FmsUserAction;
	removeCourse: Scalars['Boolean']['output'];
	removeCourseSession: Scalars['Boolean']['output'];
	removeEnrolment: Scalars['Boolean']['output'];
	removeMediaElement: Scalars['Boolean']['output'];
	removeSessionComponent: Scalars['Boolean']['output'];
	removeUser: Scalars['Boolean']['output'];
	removeUserAction: Scalars['Boolean']['output'];
	updateCourse: FmsCourse;
	updateCourseSession: FmsCourseSession;
	updateEnrolment: FmsEnrolment;
	updateMediaElement: FmsMediaElement;
	updateSessionComponent: FmsSessionComponent;
	updateUser?: Maybe<FmsUser>;
	updateUserAction: FmsUserAction;
};

export type MutationCreateCourseArgs = {
	data: NewFmsCourseInput;
};

export type MutationCreateCourseSessionArgs = {
	data: NewFmsCourseSessionInput;
};

export type MutationCreateEnrolmentArgs = {
	data: NewFmsEnrolmentInput;
};

export type MutationCreateMediaElementArgs = {
	data: NewFmsMediaElementInput;
};

export type MutationCreateSessionComponentArgs = {
	data: NewFmsSessionComponentInput;
};

export type MutationCreateUserArgs = {
	data: NewFmsUserInput;
};

export type MutationCreateUserActionArgs = {
	data: NewFmsUserActionInput;
};

export type MutationRemoveCourseArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveCourseSessionArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveEnrolmentArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveMediaElementArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveSessionComponentArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveUserArgs = {
	id: Scalars['ID']['input'];
};

export type MutationRemoveUserActionArgs = {
	id: Scalars['ID']['input'];
};

export type MutationUpdateCourseArgs = {
	data: UpdateFmsCourseInput;
};

export type MutationUpdateCourseSessionArgs = {
	data: UpdateFmsCourseSessionInput;
};

export type MutationUpdateEnrolmentArgs = {
	data: UpdateFmsEnrolmentInput;
};

export type MutationUpdateMediaElementArgs = {
	data: UpdateFmsMediaElementInput;
};

export type MutationUpdateSessionComponentArgs = {
	data: UpdateFmsSessionComponentInput;
};

export type MutationUpdateUserArgs = {
	data: UpdateFmsUserInput;
};

export type MutationUpdateUserActionArgs = {
	data: UpdateFmsUserActionInput;
};

export type NewFmsCourseInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

export type NewFmsCourseSessionInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

export type NewFmsEnrolmentInput = {
	confirmCorrectInfo: Scalars['Boolean']['input'];
	consentToInfoSharedWithLicenseHolder: Scalars['Boolean']['input'];
	consentToResearchEvaluationContact: Scalars['Boolean']['input'];
	consentToSms: Scalars['Boolean']['input'];
	courseId: Scalars['ID']['input'];
	description?: InputMaybe<Scalars['String']['input']>;
	licenseKey: Scalars['String']['input'];
	organizationId: Scalars['String']['input'];
	organizationType: FmsOrganizationType;
	title?: InputMaybe<Scalars['String']['input']>;
	userId: Scalars['ID']['input'];
};

export type NewFmsMediaElementInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

export type NewFmsSessionComponentInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

export type NewFmsUserActionInput = {
	actionType: UserActionType;
	componentId: Scalars['ID']['input'];
	description?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
	userId: Scalars['ID']['input'];
};

export type NewFmsUserInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	email?: InputMaybe<Scalars['String']['input']>;
	emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
	firstName?: InputMaybe<Scalars['String']['input']>;
	imageUrl?: InputMaybe<Scalars['String']['input']>;
	lastName?: InputMaybe<Scalars['String']['input']>;
	nickName?: InputMaybe<Scalars['String']['input']>;
	phone?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
	__typename?: 'Query';
	course: FmsCourse;
	courseSession: FmsCourseSession;
	courseSessions: Array<FmsCourseSession>;
	courses: Array<FmsCourse>;
	currentUser?: Maybe<FmsUser>;
	enrolment: FmsEnrolment;
	enrolments: Array<FmsEnrolment>;
	mediaElement: FmsMediaElement;
	mediaElements: Array<FmsMediaElement>;
	sessionComponent: FmsSessionComponent;
	sessionComponents: Array<FmsSessionComponent>;
	user: FmsUser;
	userAction: FmsUserAction;
	userActions: Array<FmsUserAction>;
	userFavorites: Array<FmsUserAction>;
	users: Array<FmsUser>;
};

export type QueryCourseArgs = {
	id: Scalars['ID']['input'];
};

export type QueryCourseSessionArgs = {
	id: Scalars['ID']['input'];
};

export type QueryCourseSessionsArgs = {
	courseId: Scalars['ID']['input'];
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
	theme: CourseSessionTheme;
	type: CourseSessionType;
};

export type QueryCoursesArgs = {
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

export type QueryEnrolmentArgs = {
	id: Scalars['ID']['input'];
};

export type QueryEnrolmentsArgs = {
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

export type QueryMediaElementArgs = {
	id: Scalars['ID']['input'];
};

export type QueryMediaElementsArgs = {
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

export type QuerySessionComponentArgs = {
	id: Scalars['ID']['input'];
};

export type QuerySessionComponentsArgs = {
	componentType?: InputMaybe<SessionComponentType>;
	courseId: Scalars['ID']['input'];
	sessionTheme?: InputMaybe<CourseSessionTheme>;
	sessionType?: InputMaybe<CourseSessionType>;
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

export type QueryUserArgs = {
	id: Scalars['ID']['input'];
};

export type QueryUserActionArgs = {
	id: Scalars['ID']['input'];
};

export type QueryUserActionsArgs = {
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

export type QueryUsersArgs = {
	skip?: Scalars['Int']['input'];
	take?: Scalars['Int']['input'];
};

/** The type of session component */
export enum SessionComponentType {
	Introduction = 'Introduction',
	Meditation = 'Meditation',
	Other = 'Other',
	Unknown = 'Unknown',
}

export type UpdateFmsCourseInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsCourseSessionInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsEnrolmentInput = {
	activeTheme?: InputMaybe<CourseSessionTheme>;
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
	status?: InputMaybe<EnrolmentStatus>;
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsMediaElementInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsSessionComponentInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsUserActionInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFmsUserInput = {
	address1?: InputMaybe<Scalars['String']['input']>;
	address2?: InputMaybe<Scalars['String']['input']>;
	city?: InputMaybe<Scalars['String']['input']>;
	consentGeneral?: InputMaybe<Scalars['Boolean']['input']>;
	firstName?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	lastName?: InputMaybe<Scalars['String']['input']>;
	phone?: InputMaybe<Scalars['String']['input']>;
	postalCode?: InputMaybe<Scalars['Float']['input']>;
	smsConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The basic user action types tracked */
export enum UserActionType {
	Favorite = 'Favorite',
	Other = 'Other',
	Seen = 'Seen',
}
