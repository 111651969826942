import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

export enum TwBreakpoint {
	None = '(max-width: 639px)',
	Sm = '(min-width: 640px)',
	Md = '(min-width: 768px)',
	Lg = '(min-width: 1024px)',
	Xl = '(min-width: 1280px)',
	Xxl = '(min-width: 1536px)', // 2xl
}

@Injectable({ providedIn: 'root' })
export class FmsLayoutService {
	readonly #breakpointObserver = inject(BreakpointObserver);
	curBreakpointState!: {
		[key in TwBreakpoint]: boolean;
	};
	readonly #allBreakPointsSubject = new BehaviorSubject<{
		[key in TwBreakpoint]: boolean;
	}>({
		[TwBreakpoint.None]: this.#breakpointObserver.isMatched(TwBreakpoint.None),
		[TwBreakpoint.Sm]: this.#breakpointObserver.isMatched(TwBreakpoint.Sm),
		[TwBreakpoint.Md]: this.#breakpointObserver.isMatched(TwBreakpoint.Md),
		[TwBreakpoint.Lg]: this.#breakpointObserver.isMatched(TwBreakpoint.Lg),
		[TwBreakpoint.Xl]: this.#breakpointObserver.isMatched(TwBreakpoint.Xl),
		[TwBreakpoint.Xxl]: this.#breakpointObserver.isMatched(TwBreakpoint.Xxl),
	});
	readonly allBreakpoints$: Observable<{
		[key in TwBreakpoint]: boolean;
	}> = this.#allBreakPointsSubject.asObservable();

	readonly #noneBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.None)
	);
	readonly noneBreakpoint$ = this.#noneBreakpointSubject.asObservable();

	readonly #smBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.Sm)
	);
	readonly smBreakpoint$ = this.#smBreakpointSubject.asObservable();

	readonly #mdBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.Md)
	);
	readonly mdBreakpoint$ = this.#mdBreakpointSubject.asObservable();

	readonly #lgBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.Lg)
	);
	readonly lgBreakpoint$ = this.#lgBreakpointSubject.asObservable();

	readonly #xlBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.Xl)
	);
	readonly xlBreakpoint$ = this.#xlBreakpointSubject.asObservable();

	readonly #xxlBreakpointSubject = new BehaviorSubject<boolean>(
		this.#breakpointObserver.isMatched(TwBreakpoint.Xxl)
	);
	readonly xxlBreakpoint$ = this.#xxlBreakpointSubject.asObservable();
	constructor() {
		const tailwindBreakpoints = [
			TwBreakpoint.None,
			TwBreakpoint.Sm,
			TwBreakpoint.Md,
			TwBreakpoint.Lg,
			TwBreakpoint.Xl,
			TwBreakpoint.Xxl,
		];
		this.#breakpointObserver
			.observe(tailwindBreakpoints)
			.subscribe((result) => {
				this.#allBreakPointsSubject.next(
					result.breakpoints as {
						[key in TwBreakpoint]: boolean;
					}
				);
				this.#noneBreakpointSubject.next(result.breakpoints[TwBreakpoint.None]);
				this.#smBreakpointSubject.next(result.breakpoints[TwBreakpoint.Sm]);
				this.#mdBreakpointSubject.next(result.breakpoints[TwBreakpoint.Md]);
				this.#lgBreakpointSubject.next(result.breakpoints[TwBreakpoint.Lg]);
				this.#xlBreakpointSubject.next(result.breakpoints[TwBreakpoint.Xl]);
				this.#xxlBreakpointSubject.next(result.breakpoints[TwBreakpoint.Xxl]);
			});
		this.allBreakpoints$.subscribe(
			(result) => (this.curBreakpointState = result)
		);
	}
}
