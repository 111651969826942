<div class="mx-5 my-5">
	<nz-result
		[nzTitle]="errorDetails.title"
		[nzStatus]="errorDetails.status"
		[nzSubTitle]="errorDetails.subTitle"
	>
		<div nz-result-extra>
			<button
				nz-button
				nzSize="large"
				*ngIf="errorDetails.allowPageRefresh"
				[nzType]="errorDetails.allowResetSession ? 'default' : 'primary'"
				(click)="refetchPage()"
			>
				Genhent side
			</button>
			<button
				nz-button
				nzSize="large"
				*ngIf="errorDetails.allowReloadUser"
				[nzType]="errorDetails.allowResetSession ? 'default' : 'primary'"
				(click)="retry()"
			>
				Genhent bruger-profil
			</button>
			<button
				nz-button
				nzSize="large"
				*ngIf="errorDetails.allowResetSession"
				nzType="primary"
				(click)="reset()"
			>
				Logud
			</button>
		</div>
	</nz-result>
</div>
