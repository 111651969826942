<header>
	<div class="mx-auto max-w-7xl px-4 pt-4 sm:px-6 lg:px-8">
		<div class="lg:flex lg:items-center lg:justify-between">
			<div class="min-w-0 flex-1">
				<ng-content select="fms-page-header-title" />
				<ng-content select="fms-page-header-description" />
			</div>
			<ng-content select="fms-page-header-actions" />
		</div>
	</div>
</header>
