import { CourseSessionTheme, FmsOrganizationType } from '@fms/ts-gql-types';

export const LANDING_PAGE_SOURCE_STORAGE_KEY =
	'LANDING_PAGE_SOURCE_STORAGE_KEY';

export enum FmsCourseId {
	Citizen = 'cbf05401-5aa7-4929-ae0e-e5364b9bd1a6',
	Worklife = '48d92749-3c7f-4126-a75e-d724be512c9e',
}

export enum FmsNodeType {
	FmsUser = 'FmsUser',
	FmsCourse = 'FmsCourse',
	FmsCourseSession = 'FmsCourseSession',
	FmsMediaElement = 'FmsMediaElement',
	FmsSessionComponent = 'FmsSessionComponent',
	FmsUserAction = 'FmsUserAction',
	FmsEnrolment = 'FmsEnrolment',
}

export const fmsCourseThemes: CourseSessionTheme[] = [
	CourseSessionTheme.Strategies,
	CourseSessionTheme.Body,
	CourseSessionTheme.ThoughtsAndFeelings,
	CourseSessionTheme.SocialRelations,
	CourseSessionTheme.TheFocusYouWant,
];

export const fmsThemeIcons: { [key in CourseSessionTheme]: string } = {
	[CourseSessionTheme.Strategies]: 'fms_method_icon.svg',
	[CourseSessionTheme.Body]: 'fms_body_icon.svg',
	[CourseSessionTheme.ThoughtsAndFeelings]: 'fms_mind_icon.svg',
	[CourseSessionTheme.SocialRelations]: 'fms_human_intercourse_icon.svg',
	[CourseSessionTheme.TheFocusYouWant]: 'fms_meaning_icon.svg',
	[CourseSessionTheme.Other]: 'logo.png',
	[CourseSessionTheme.Unknown]: 'logo.png',
};

export const fmsThemeIconClasses: { [key in CourseSessionTheme]: string[] } = {
	[CourseSessionTheme.Strategies]: ['w-20', 'h-20'],
	[CourseSessionTheme.Body]: ['w-22', 'h-22', '-mt-2', 'pt-2'],
	[CourseSessionTheme.ThoughtsAndFeelings]: ['w-22', 'h-22', '-mt-2', 'pt-2'],
	[CourseSessionTheme.SocialRelations]: ['w-20', 'h-20'],
	[CourseSessionTheme.TheFocusYouWant]: ['w-20', 'h-20'],
	[CourseSessionTheme.Other]: ['w-20', 'h-20'],
	[CourseSessionTheme.Unknown]: ['w-20', 'h-20'],
};

export interface IFmsClientOrganization {
	type: FmsOrganizationType;
	id: FmsClientOrganizationId;
	label: string;
}

export interface IFmsClientOrganization {
	type: FmsOrganizationType;
	id: FmsClientOrganizationId;
	label: string;
}

export enum FmsClientOrganizationId {
	FondenMentalSundhedBusiness = 'Business|41516178',
	FondenMentalSundhedMunicipality = 'Municipality|41516178',
	HeyLouise = 'Business|40442863',
	København = 'Municipality|101',
	BrandByHand = 'Business|36479388',
	Vertica = 'Business|26263573',
	Trifork = 'Business|20921897',
	Taarnby = 'Business|20310413',
	Duckwise = 'Business|33574398',
	SmartDevice = 'Business|38119206',
	Kombit = 'Business|19435975',
	HospitalKlovne = 'Business|27150632',
	GreenPeace = 'Business|89198313',
	Byplanlabo = 'Business|56736115',
	BKI = 'Business|56312412',
	Velliv = 'Business|FMS_Velliv',
	Arbejdsliv = 'Business|FMS_Arbejdsliv',
	Ledelse = 'Business|FMS_Ledelse',
	Aalborg = 'Municipaltiy|851',
	Skanderborg = 'Municipality|746',
	Mariager = 'Municipality|846',
	Sonderborg = 'Municipality|540',
	Rudersdal = 'Municipality|230',
	Gladsaxe = 'Municipality|159',
	Solrod = 'Municipality|269',
	Brondby = 'Municipality|153',
	HojeTaastrup = 'Municipality|169',
	SpringbraetAarhus = 'Business|58961914',
	EtAl = 'Business|36707836',
	OdenseUniversitetshospital = 'Business|29190909',
	FredericiaDagblad = 'Business|36503335',
	HK = 'Business|53250017',
	KøbenhavnBusiness = 'Business|101',
	GladsaxeBusiness = 'Business|159',
	CpProjektet = 'Course|0001',
	DM = 'Business|17542028',
	PFA = 'Business|13594376',
	Helsingor = 'Municipality|217',
}

export const fmsClientOrgOptions: Array<IFmsClientOrganization> = [
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.BKI,
		label: 'BKI Foods A/S',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.BrandByHand,
		label: 'Brand by Hand ApS',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Ledelse,
		label: 'Bæredygtigt Ledelse',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Arbejdsliv,
		label: 'Bæredygtigt Arbejdsliv',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Byplanlabo,
		label: 'Dansk Byplanlaboratorium',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.HospitalKlovne,
		label: 'Dansk Hospitalsklovne',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Duckwise,
		label: 'Duckwise A/S',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.FondenMentalSundhedBusiness,
		label: 'Fonden Mental Sundhed',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.GreenPeace,
		label: 'Greenpeace',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.HeyLouise,
		label: 'HeyLouise',
	},
	{
		type: FmsOrganizationType.Business, // Needs access to worklife course
		id: FmsClientOrganizationId.HojeTaastrup,
		label: 'Høje-Taastrup Kommune',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Kombit,
		label: 'KOMBIT A/S',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.SmartDevice,
		label: 'Smart Device ApS',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Trifork,
		label: 'Trifork A/S',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Taarnby,
		label: 'Tårnby Kommune',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Velliv,
		label: 'Velliv',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.Vertica,
		label: 'Vertica A/S',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Aalborg,
		label: 'Aalborg Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Gladsaxe,
		label: 'Gladsaxe Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.København,
		label: 'Københavns Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Mariager,
		label: 'Mariager Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Rudersdal,
		label: 'Rudersdal Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Skanderborg,
		label: 'Skanderborg Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Solrod,
		label: 'Solrød Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Sonderborg,
		label: 'Sønderborg Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.FondenMentalSundhedMunicipality,
		label: 'Fonden Mental Sundhed',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Brondby,
		label: 'Brøndby Kommune',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.SpringbraetAarhus,
		label: 'Springbræt Aarhus',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.EtAl,
		label: 'Et al',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.OdenseUniversitetshospital,
		label: 'Odense Universitetshospital',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.FredericiaDagblad,
		label: 'Fredericia Dagblad',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.HK,
		label: 'HK',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.KøbenhavnBusiness,
		label: 'Københavns Kommune',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.CpProjektet,
		label: 'CP-projektet',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.DM,
		label: 'DM',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.GladsaxeBusiness,
		label: 'Gladsaxe Kommune',
	},
	{
		type: FmsOrganizationType.Business,
		id: FmsClientOrganizationId.PFA,
		label: 'PFA',
	},
	{
		type: FmsOrganizationType.Municipality,
		id: FmsClientOrganizationId.Helsingor,
		label: 'Helsingør Kommune',
	},
];
