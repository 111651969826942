<button
	type="button"
	[disabled]="disabled"
	[class]="{
		'opacity-25': disabled,
		'cursor-not-allowed': disabled,
		'bg-fmsDarkBlue': type === 'primary',
		'hover:bg-fmsLightBlue': type === 'primary',
		'bg-fmsLightBlue': type === 'secondary',
		'hover:bg-fmsDarkBlue': type === 'secondary',
		'text-white': type !== 'default',
		'text-gray-700': type === 'default',
		'hover:bg-gray-50': type === 'default'
	}"
	class="inline-flex items-center whitespace-nowrap rounded-md border border-gray-300 px-2 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:px-4"
>
	<ng-content />
</button>
