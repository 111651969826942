<ng-container *ngIf="modalData.component$ | async as component">
	<div *nzModalTitle>
		<span class="text-base md:text-lg lg:text-lg">
			{{ modalData.title }}
		</span>
		<ng-container *ngIf="modalData.subTitle">
			<fms-chip *ngIf="isArray(modalData.subTitle); else stringSubTitle">
				<ng-container
					*ngFor="let elem of modalData.subTitle; let last = last"
				>
					{{ elem }}
					<nz-divider *ngIf="!last" nzType="vertical" />
				</ng-container>
			</fms-chip>
			<ng-template #stringSubTitle>
				<fms-chip>{{ stringSubTitle }}</fms-chip>
			</ng-template>
		</ng-container>
	</div>
	<div class="p-4">
		<ng-container *ngIf="modalData.audioUrl">
			<audio
				[src]="modalData.audioUrl"
				preload
				controls
				class="w-full"
			></audio>
		</ng-container>
	</div>
	<div class="p-4" *ngIf="component?.aboveFoldContent">
		<div class="text-base font-bold md:text-lg">
			{{ modalData.textContentTitle ? modalData.textContentTitle : 'Om' }}
		</div>
		<div>
			<p>{{ component.aboveFoldContent }}</p>
			<p *ngIf="showBelowFold">{{ component?.belowFoldContent }}</p>
			<fms-button
				*ngIf="component?.belowFoldContent"
				(click)="toggleBelowFoldContent()"
			>
				{{ showBelowFold ? 'Læs mindre' : 'Læs mere' }}
			</fms-button>
		</div>
	</div>
	<div *nzModalFooter class="h-12">
		<fms-toggle-favorite-button
			class="float-left"
			mode="button"
			[componentId]="component.id"
			[actionId]="component?.favoriteAction?.id"
		/>
		<fms-button
			*ngIf="modalData.relatedCourseSessionId !== null"
			class="float-left ml-2"
			(click)="goToRelatedCourseSession()"
		>
			<span class="mr-2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
					/>
				</svg>
			</span>
			Gå til temadag
		</fms-button>
		<fms-button class="float-right" (click)="closeModal()">
			<span class="mr-2">
				<svg
					class="h-6 opacity-50"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M5 13l4 4L19 7"
					/>
				</svg>
			</span>
			Luk
		</fms-button>
	</div>
</ng-container>
