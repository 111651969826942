import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-page-header',
	templateUrl: 'page-header.component.html',
	styleUrls: ['page-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {}
