import { Capacitor } from '@capacitor/core';

import { inject } from '@angular/core';
import {
	CanActivateFn,
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';

import { of, combineLatest, interval, race, Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';

import { UserFacade } from '@fms/ngx-user';

/**
 * Based on https://github.com/auth0/auth0-angular/blob/master/projects/auth0-angular/src/lib/auth.guard.ts
 *
 * But with added handling of permission denied in login
 */
export const isAuthenticatedGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean> => {
	const auth0 = inject(AuthService);
	const userFacade = inject(UserFacade);
	const router = inject(Router);

	return combineLatest([
		auth0.isAuthenticated$.pipe(take(1)),
		race(auth0.error$, interval(20).pipe(map(() => null))),
	]).pipe(
		map(([loggedIn, error]) => {
			return { loggedIn, error };
		}),
		switchMap((result) => {
			if (!result.loggedIn) {
				if (result.error !== null) {
					if (result.error?.error === 'unauthorized') {
						router.navigate([
							'/',
							'no-access',
							getNoAccessErrorCode(result.error),
						]);
					} else {
						auth0.logout();
					}
				} else {
					if (Capacitor.isNativePlatform()) {
						userFacade.initiateCapacitorLogin();
					} else {
						auth0.loginWithRedirect({
							appState: { target: state.url },
						});
					}
				}
				return of(false);
			} else {
				return of(true);
			}
		})
	);
};

/**
 * Codes:
 * 0 - unknown error
 * 1 - not whitelisted
 * 2 - verify email
 * @param error result.error
 */
const getNoAccessErrorCode = (error) => {
	if (!error?.error_description) {
		return 0;
	}
	switch (error.error_description) {
		case 'Your email is not whitelisted':
			return 1;
		case 'Please verify your email before logging in':
			return 2;
		default:
			return 0;
	}
};
