import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	TemplateRef,
} from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-card',
	templateUrl: 'card.component.html',
	styleUrls: ['card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, NgTemplateOutlet],
})
export class CardComponent {
	@Input() cardIconSrc: string | null = null;
	@Input() cardIconClasses: string[] = ['w-20', 'h-20'];
	@Input() leftSlot: TemplateRef<unknown> | null = null;
	@Input() rightSlot: TemplateRef<unknown> | null = null;
	@Input() inactive = false;
}
