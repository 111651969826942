import { HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';

import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

/**
 * Interceptor function for adding api token to requests to API
 * https://auth0.com/docs/quickstart/spa/angular2/02-calling-an-api
 */
export const apiInterceptor = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const auth = inject(AuthService);
	// If request is directed at api, add token to authorization header. Else, handle request normally
	if (req.url.includes(environment.apiUrl)) {
		return auth
			.getAccessTokenSilently({
				authorizationParams: { audience: environment.apiUrl },
			})
			.pipe(
				mergeMap((token) => {
					const tokenReq = req.clone({
						// eslint-disable-next-line @typescript-eslint/naming-convention
						setHeaders: { Authorization: `Bearer ${token}` },
					});
					return next(tokenReq);
				}),
				catchError((err) => throwError(() => err))
			);
	} else {
		return next(req);
	}
};
