import { createErrorHandler } from '@sentry/angular-ivy';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MarkdownModule } from 'ngx-markdown';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import da from '@angular/common/locales/da';
import {
	enableProdMode,
	ErrorHandler,
	importProvidersFrom,
	isDevMode,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { AuthModule } from '@auth0/auth0-angular';

import { provideEffects } from '@ngrx/effects';
import { Action, ActionReducer, MetaReducer, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { NZ_I18N, da_DK } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { FmsNgxUserStateModule, USER_FEATURE_KEY } from '@fms/ngx-user';
import { FmsNgxVimeoModule } from '@fms/ngx-vimeo';
import { FmsRoutePath } from '@fms/shared-models';

import { AppComponent, FMS_APP_ROUTES } from './app/app.component';
import { FmsGraphQLModule } from './app/graphql.module';
import { apiInterceptor } from './app/interceptors/api.interceptor';
import { FmsAppUserEffects } from './app/store/user.effects';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

registerLocaleData(da);

export function localStorageSyncReducer(
	reducer: ActionReducer<unknown>
): ActionReducer<unknown> {
	return localStorageSync({ keys: [USER_FEATURE_KEY], rehydrate: true })(
		reducer
	);
}
const metaReducers: Array<MetaReducer<unknown, Action>> = [
	localStorageSyncReducer,
];

const sentryProviders = environment.sentry.enabled
	? [
			{
				provide: ErrorHandler,
				useValue: createErrorHandler({
					showDialog: environment.sentry.showErrorDialog,
				}),
			},
	  ]
	: [];

bootstrapApplication(AppComponent, {
	providers: [
		provideAnimations(),
		provideHttpClient(
			withInterceptors([
				apiInterceptor,
				// They can be inlined too, with full type safety!
				(req, next) => next(req),
			])
		),
		provideRouter(FMS_APP_ROUTES),
		{ provide: NZ_I18N, useValue: da_DK },
		...sentryProviders,
		importProvidersFrom(
			FmsNgxVimeoModule.forRoot({
				clientId: '1c270846a8447d55a79e3deb37271d027afa436f',
				clientSecret:
					'7On1FLnartQItMXT328J1HBnHBcmNBdZR941KJqOQETX4In6X0NKtFXvV+RDHmBeiZSI7jj4schzaLRc2dfUfK2jCTnYD760Im8XbaanpKjUfgUB9tmLBpWE4KepZFys',
			})
		),
		importProvidersFrom(
			AuthModule.forRoot({
				// Auth0 module
				domain: environment.auth0.domain,
				clientId: environment.auth0.clientId,
				authorizeTimeoutInSeconds: environment.auth0.authorizeTimeoutInSeconds,
				cacheLocation: environment.auth0.cacheLocation,
				authorizationParams: {
					audience: environment.apiUrl,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					redirect_uri: environment.auth0.redirectUri,
				},
			})
		),
		provideStore(
			{},
			{
				metaReducers,
				runtimeChecks: {
					strictActionImmutability: true,
					strictStateImmutability: true,
				},
			}
		),
		importProvidersFrom(
			FmsNgxUserStateModule.forRoot({
				courseRoute: ['/', FmsRoutePath.Dashboard],
				capacitorLogoutReturnTo: environment.auth0.redirectUri,
				isCapacitorApp: environment.envName === 'capacitor',
				auth0ClientId: environment.auth0.clientId,
			})
		),
		provideStoreDevtools({ logOnly: !isDevMode(), connectOutsideZone: true }),
		provideEffects([FmsAppUserEffects]),
		importProvidersFrom(FmsGraphQLModule),
		importProvidersFrom(NzModalModule),
		importProvidersFrom(MarkdownModule.forRoot()),
	],
});
