/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';
import { MinimalCourseSessionFragmentDoc } from '../course-session/course-session.service';

export type MinimalCourseFragment = {
	__typename: 'FmsCourse';
	id: string;
	title?: string | null;
	description?: string | null;
	imageUrl?: string | null;
};

export type DefaultCourseFragment = {
	__typename: 'FmsCourse';
	id: string;
	title?: string | null;
	description?: string | null;
	imageUrl?: string | null;
	sessions?: Array<{
		__typename: 'FmsCourseSession';
		id: string;
		title?: string | null;
		description?: string | null;
		type: SchemaTypes.CourseSessionType;
		theme: SchemaTypes.CourseSessionTheme;
		themeNumber?: number | null;
		themeSessionNumber: number;
		course?: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
		} | null;
		introComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
		meditationComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
		otherComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
	} | null> | null;
};

export type GetCourseQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCourseQuery = {
	__typename?: 'Query';
	course: {
		__typename: 'FmsCourse';
		id: string;
		title?: string | null;
		description?: string | null;
		imageUrl?: string | null;
		sessions?: Array<{
			__typename: 'FmsCourseSession';
			id: string;
			title?: string | null;
			description?: string | null;
			type: SchemaTypes.CourseSessionType;
			theme: SchemaTypes.CourseSessionTheme;
			themeNumber?: number | null;
			themeSessionNumber: number;
			course?: {
				__typename?: 'FmsCourseSession';
				id: string;
				title?: string | null;
			} | null;
			introComponent?: {
				__typename: 'FmsSessionComponent';
				belowFoldContent?: string | null;
				legacyUrl?: string | null;
				id: string;
				title?: string | null;
				description?: string | null;
				componentType: SchemaTypes.SessionComponentType;
				aboveFoldContent?: string | null;
				mediaElement?: {
					__typename: 'FmsMediaElement';
					id: string;
					title?: string | null;
					description?: string | null;
					mediaType: SchemaTypes.MediaType;
					duration?: number | null;
					srcProvider: SchemaTypes.FmsMediaProvider;
					srcId?: string | null;
					srcUrl?: string | null;
				} | null;
				seenAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
				favoriteAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
			} | null;
			meditationComponent?: {
				__typename: 'FmsSessionComponent';
				belowFoldContent?: string | null;
				legacyUrl?: string | null;
				id: string;
				title?: string | null;
				description?: string | null;
				componentType: SchemaTypes.SessionComponentType;
				aboveFoldContent?: string | null;
				mediaElement?: {
					__typename: 'FmsMediaElement';
					id: string;
					title?: string | null;
					description?: string | null;
					mediaType: SchemaTypes.MediaType;
					duration?: number | null;
					srcProvider: SchemaTypes.FmsMediaProvider;
					srcId?: string | null;
					srcUrl?: string | null;
				} | null;
				seenAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
				favoriteAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
			} | null;
			otherComponent?: {
				__typename: 'FmsSessionComponent';
				belowFoldContent?: string | null;
				legacyUrl?: string | null;
				id: string;
				title?: string | null;
				description?: string | null;
				componentType: SchemaTypes.SessionComponentType;
				aboveFoldContent?: string | null;
				mediaElement?: {
					__typename: 'FmsMediaElement';
					id: string;
					title?: string | null;
					description?: string | null;
					mediaType: SchemaTypes.MediaType;
					duration?: number | null;
					srcProvider: SchemaTypes.FmsMediaProvider;
					srcId?: string | null;
					srcUrl?: string | null;
				} | null;
				seenAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
				favoriteAction?: {
					__typename: 'FmsUserAction';
					id: string;
					actionType: SchemaTypes.UserActionType;
					createdAt: any;
					updatedAt: any;
					user: { __typename?: 'FmsUser'; id: string };
					component: { __typename?: 'FmsSessionComponent'; id: string };
				} | null;
			} | null;
		} | null> | null;
	};
};

export const MinimalCourseFragmentDoc = gql`
	fragment minimalCourse on FmsCourse {
		__typename
		id
		title
		description
		imageUrl
	}
`;
export const DefaultCourseFragmentDoc = gql`
	fragment defaultCourse on FmsCourse {
		...minimalCourse
		sessions {
			...minimalCourseSession
		}
	}
	${MinimalCourseFragmentDoc}
	${MinimalCourseSessionFragmentDoc}
`;
export const GetCourseDocument = gql`
	query getCourse($id: ID!) {
		course(id: $id) {
			...defaultCourse
		}
	}
	${DefaultCourseFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetCourseGQL extends Apollo.Query<
	GetCourseQuery,
	GetCourseQueryVariables
> {
	document = GetCourseDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class CourseGQLService {
	constructor(private getCourseGql: GetCourseGQL) {}

	getCourse(
		variables: GetCourseQueryVariables,
		options?: QueryOptionsAlone<GetCourseQueryVariables>
	) {
		return this.getCourseGql.fetch(variables, options);
	}

	getCourseWatch(
		variables: GetCourseQueryVariables,
		options?: WatchQueryOptionsAlone<GetCourseQueryVariables>
	) {
		return this.getCourseGql.watch(variables, options);
	}
}
