import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-page-body',
	templateUrl: 'page-body.component.html',
	styleUrls: ['page-body.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class PageBodyComponent {}
