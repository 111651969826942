<ng-container
	*ngIf="modalData.component$ | async as component; else noComponent"
>
	<ng-container
		[ngTemplateOutlet]="core"
		[ngTemplateOutletContext]="{ component: component }"
	/>
</ng-container>

<ng-template #noComponent>
	<ng-container *ngTemplateOutlet="core; context: null" />
</ng-template>

<ng-template #core let-component="component">
	<div *nzModalTitle>
		<span class="text-base md:text-lg lg:text-lg">
			{{ modalData.title }}
		</span>
		<ng-container *ngIf="modalData.subTitle">
			<fms-chip *ngIf="isArray(modalData.subTitle); else stringSubTitle">
				<ng-container
					*ngFor="let elem of modalData.subTitle; let last = last"
				>
					{{ elem }}
					<nz-divider *ngIf="!last" nzType="vertical" />
				</ng-container>
			</fms-chip>
			<ng-template #stringSubTitle>
				<fms-chip>{{ modalData.subTitle }}</fms-chip>
			</ng-template>
		</ng-container>
	</div>
	<fms-vimeo-player
		[playerOptions]="{ id: modalData.videoId, autoplay: true }"
	/>
	<div class="p-4" *ngIf="component?.aboveFoldContent">
		<div class="text-lg font-bold">
			{{ modalData.textContentTitle ? modalData.textContentTitle : 'Om' }}
		</div>
		<div>
			<p>{{ component?.aboveFoldContent }}</p>
			<p *ngIf="showBelowFold">{{ component?.belowFoldContent }}</p>
			<fms-button
				*ngIf="component?.belowFoldContent"
				(click)="toggleBelowFoldContent()"
			>
				{{ showBelowFold ? 'Læs mindre' : 'Læs mere' }}
			</fms-button>
		</div>
	</div>
	<div *nzModalFooter class="h-12">
		<fms-toggle-favorite-button
			*ngIf="component"
			[hidden]="modalData.hideFavoriteBtn"
			class="float-left"
			mode="button"
			[componentId]="component.id"
			[actionId]="component?.favoriteAction?.id"
		/>
		<fms-button class="float-right" (click)="closeModal()">
			<span class="mr-2">
				<svg
					class="h-6 opacity-50"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M5 13l4 4L19 7"
					/>
				</svg>
			</span>
			Luk
		</fms-button>
	</div>
</ng-template>
