<nav class="mx-auto max-w-7xl pt-6 sm:px-6 lg:px-8">
	<ol class="flex space-x-4 rounded-md bg-white px-6 shadow">
		<li class="flex" *ngIf="showHome">
			<div class="flex items-center">
				<a
					[routerLink]="homePath !== null ? homePath : undefined"
					routerLinkActive="router-link-active"
					class="text-gray-400 hover:text-gray-500"
				>
					<!-- Heroicon name: solid/home -->
					<svg
						class="h-5 w-5 flex-shrink-0"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
						/>
					</svg>
					<span class="sr-only">Home</span>
				</a>
			</div>
		</li>
		<ng-content />
	</ol>
</nav>
