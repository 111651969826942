import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-card-footer',
	templateUrl: 'card-footer.component.html',
	styleUrls: ['card-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class CardFooterComponent {}
