import { inject } from '@angular/core';
import {
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateFn,
} from '@angular/router';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs';

import { UserFacade } from '@fms/ngx-user';
import { FmsRoutePath } from '@fms/shared-models';

export const isNotEnrolledUserGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Observable<boolean> => {
	const userFacade = inject(UserFacade);
	const router = inject(Router);
	return userFacade.authState$.pipe(
		first((result) => result !== null),
		map((authState) => {
			const isEnrolled = authState.curUser.enrolments.length > 0;
			if (isEnrolled) {
				router.navigate(['/', FmsRoutePath.AppFrame, FmsRoutePath.Dashboard]);
			}
			return !isEnrolled;
		})
	);
};
