import { createReducer, on, Action } from '@ngrx/store';

import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
	firstName: string;
	lastName: string;
	imageUrl: string;
	email: string;
	emailVerified: boolean;
	locale: string;
	userId: string;
	userSub: string;
	auth0UpdatedAt: string;
	curCourseName: string;
	curCourseLogo: string;
	curCourseId: string;
	curCourseEnrolmentId: string;
	curCourseOnboardingCompleted: boolean;
	userLoaded: boolean;
}

export interface UserPartialState {
	readonly [USER_FEATURE_KEY]: UserState;
}

export const initialState: UserState = {
	firstName: null,
	lastName: null,
	imageUrl: null,
	email: null,
	emailVerified: null,
	locale: null,
	userId: null,
	userSub: null,
	auth0UpdatedAt: null,
	curCourseId: null,
	curCourseName: null,
	curCourseLogo: null,
	curCourseEnrolmentId: null,
	curCourseOnboardingCompleted: null,
	userLoaded: false,
};

const userReducer = createReducer(
	initialState,
	on(UserActions.updateUser, (state, action) => {
		return { ...state, ...action };
	}),
	on(UserActions.loadUser, (state, action) => {
		return { ...state, ...action };
	}),
	on(UserActions.changeCourse, (state, action) => {
		return { ...state, ...action };
	}),
	on(UserActions.logout, () => {
		return initialState;
	})
);

export function reducer(state: UserState | undefined, action: Action) {
	return userReducer(state, action);
}
