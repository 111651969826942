import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-page-header-actions',
	templateUrl: 'page-header-actions.component.html',
	styleUrls: ['page-header-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class PageHeaderActionsComponent {}
