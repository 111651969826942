import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-page-header-description',
	templateUrl: 'page-header-description.component.html',
	styleUrls: ['page-header-description.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class PageHeaderDescriptionComponent {}
