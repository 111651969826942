import { Injectable, inject } from '@angular/core';

import { DocumentNode } from '@apollo/client/core';

import { FmsNodeType } from '@fms/shared-models';
import { Exact } from '@fms/ts-gql-types';

import { CourseSessionGQLService } from './documents/resources/course-session/course-session.service';
import {
	CourseGQLService,
	GetCourseDocument,
} from './documents/resources/course/course.service';
import {
	EnrolmentGQLService,
	GetEnrolmentDocument,
} from './documents/resources/enrolment/enrolment.service';
import {
	GetMediaElementDocument,
	MediaElementGQLService,
} from './documents/resources/media-element/media-element.service';
import {
	GetSessionComponentDocument,
	SessionComponentGQLService,
} from './documents/resources/session-component/session-component.service';
import {
	GetUserActionDocument,
	UserActionGQLService,
} from './documents/resources/user-action/user-action.service';
import { GetUserDocument, UserGQLService } from './documents/user/user.service';

export interface FmsGqlNode {
	service: object;
	gqlAlias: string;
	getQuery: DocumentNode;
	getFn: (
		variables: Exact<{
			id: string;
		}>
	) => unknown;
}

export type FmsGqlNodeMap = {
	[key in FmsNodeType]?: FmsGqlNode;
};

@Injectable({
	providedIn: 'root',
})
export class GQLService {
	readonly user = inject(UserGQLService);
	readonly course = inject(CourseGQLService);
	readonly courseSession = inject(CourseSessionGQLService);
	readonly mediaElement = inject(MediaElementGQLService);
	readonly sessionComponent = inject(SessionComponentGQLService);
	readonly enrolment = inject(EnrolmentGQLService);
	readonly userAction = inject(UserActionGQLService);
	readonly nodes: FmsGqlNodeMap = {
		[FmsNodeType.FmsUser]: {
			service: this.user,
			gqlAlias: 'user',
			getQuery: GetUserDocument,
			getFn: this.user.getUser,
		},
		[FmsNodeType.FmsCourse]: {
			service: this.course,
			gqlAlias: 'course',
			getQuery: GetCourseDocument,
			getFn: this.course.getCourse,
		},
		[FmsNodeType.FmsCourseSession]: {
			service: this.courseSession,
			gqlAlias: 'courseSession',
			getQuery: GetCourseDocument,
			getFn: this.courseSession.getCourseSession,
		},
		[FmsNodeType.FmsMediaElement]: {
			service: this.mediaElement,
			gqlAlias: 'mediaElement',
			getQuery: GetMediaElementDocument,
			getFn: this.mediaElement.getMediaElement,
		},
		[FmsNodeType.FmsSessionComponent]: {
			service: this.sessionComponent,
			gqlAlias: 'sessionComponent',
			getQuery: GetSessionComponentDocument,
			getFn: this.sessionComponent.getSessionComponent,
		},
		[FmsNodeType.FmsUserAction]: {
			service: this.userAction,
			gqlAlias: 'userAction',
			getQuery: GetUserActionDocument,
			getFn: this.userAction.getUserAction,
		},
		[FmsNodeType.FmsEnrolment]: {
			service: this.enrolment,
			gqlAlias: 'enrolment',
			getQuery: GetEnrolmentDocument,
			getFn: this.enrolment.getEnrolment,
		},
	};
}
