/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';
import { DefaultSessionComponentFragmentDoc } from '../session-component/session-component.service';

export type MinimalCourseSessionFragment = {
	__typename: 'FmsCourseSession';
	id: string;
	title?: string | null;
	description?: string | null;
	type: SchemaTypes.CourseSessionType;
	theme: SchemaTypes.CourseSessionTheme;
	themeNumber?: number | null;
	themeSessionNumber: number;
	course?: {
		__typename?: 'FmsCourseSession';
		id: string;
		title?: string | null;
	} | null;
	introComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
	meditationComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
	otherComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
};

export type DefaultCourseSessionFragment = {
	__typename: 'FmsCourseSession';
	id: string;
	title?: string | null;
	description?: string | null;
	type: SchemaTypes.CourseSessionType;
	theme: SchemaTypes.CourseSessionTheme;
	themeNumber?: number | null;
	themeSessionNumber: number;
	course?: {
		__typename?: 'FmsCourseSession';
		id: string;
		title?: string | null;
	} | null;
	introComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
	meditationComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
	otherComponent?: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	} | null;
	prevSession?: {
		__typename?: 'FmsCourseSession';
		id: string;
		title?: string | null;
		themeSessionNumber: number;
	} | null;
	nextSession?: {
		__typename?: 'FmsCourseSession';
		id: string;
		title?: string | null;
		themeSessionNumber: number;
	} | null;
};

export type GetCourseSessionQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCourseSessionQuery = {
	__typename?: 'Query';
	courseSession: {
		__typename: 'FmsCourseSession';
		id: string;
		title?: string | null;
		description?: string | null;
		type: SchemaTypes.CourseSessionType;
		theme: SchemaTypes.CourseSessionTheme;
		themeNumber?: number | null;
		themeSessionNumber: number;
		course?: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
		} | null;
		introComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
		meditationComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
		otherComponent?: {
			__typename: 'FmsSessionComponent';
			belowFoldContent?: string | null;
			legacyUrl?: string | null;
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			seenAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
			favoriteAction?: {
				__typename: 'FmsUserAction';
				id: string;
				actionType: SchemaTypes.UserActionType;
				createdAt: any;
				updatedAt: any;
				user: { __typename?: 'FmsUser'; id: string };
				component: { __typename?: 'FmsSessionComponent'; id: string };
			} | null;
		} | null;
		prevSession?: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
			themeSessionNumber: number;
		} | null;
		nextSession?: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
			themeSessionNumber: number;
		} | null;
	};
};

export const MinimalCourseSessionFragmentDoc = gql`
	fragment minimalCourseSession on FmsCourseSession {
		__typename
		id
		title
		description
		type
		theme
		themeNumber
		themeSessionNumber
		course {
			id
			title
		}
		introComponent: componentByType(componentType: Introduction) {
			...defaultSessionComponent
		}
		meditationComponent: componentByType(componentType: Meditation) {
			...defaultSessionComponent
		}
		otherComponent: componentByType(componentType: Other) {
			...defaultSessionComponent
		}
	}
	${DefaultSessionComponentFragmentDoc}
`;
export const DefaultCourseSessionFragmentDoc = gql`
	fragment defaultCourseSession on FmsCourseSession {
		...minimalCourseSession
		course {
			id
			title
		}
		introComponent: componentByType(componentType: Introduction) {
			...defaultSessionComponent
		}
		meditationComponent: componentByType(componentType: Meditation) {
			...defaultSessionComponent
		}
		otherComponent: componentByType(componentType: Other) {
			...defaultSessionComponent
		}
		prevSession {
			id
			title
			themeSessionNumber
		}
		nextSession {
			id
			title
			themeSessionNumber
		}
	}
	${MinimalCourseSessionFragmentDoc}
	${DefaultSessionComponentFragmentDoc}
`;
export const GetCourseSessionDocument = gql`
	query getCourseSession($id: ID!) {
		courseSession(id: $id) {
			...defaultCourseSession
		}
	}
	${DefaultCourseSessionFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetCourseSessionGQL extends Apollo.Query<
	GetCourseSessionQuery,
	GetCourseSessionQueryVariables
> {
	document = GetCourseSessionDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class CourseSessionGQLService {
	constructor(private getCourseSessionGql: GetCourseSessionGQL) {}

	getCourseSession(
		variables: GetCourseSessionQueryVariables,
		options?: QueryOptionsAlone<GetCourseSessionQueryVariables>
	) {
		return this.getCourseSessionGql.fetch(variables, options);
	}

	getCourseSessionWatch(
		variables: GetCourseSessionQueryVariables,
		options?: WatchQueryOptionsAlone<GetCourseSessionQueryVariables>
	) {
		return this.getCourseSessionGql.watch(variables, options);
	}
}
