<ng-container *ngIf="modalData.component$ | async as component">
	<div *nzModalTitle>
		<span class="text-base md:text-lg lg:text-lg">
			{{ modalData.title }}
		</span>
		<ng-container *ngIf="modalData.subTitle">
			<fms-chip *ngIf="isArray(modalData.subTitle); else stringSubTitle">
				<ng-container
					*ngFor="let elem of modalData.subTitle; let last = last"
				>
					{{ elem }}
					<nz-divider *ngIf="!last" nzType="vertical" />
				</ng-container>
			</fms-chip>
			<ng-template #stringSubTitle>
				<fms-chip>{{ stringSubTitle }}</fms-chip>
			</ng-template>
		</ng-container>
	</div>
	<div class="p-4">
		<div>
			<p markdown class="text-lg" *ngIf="component?.aboveFoldContent">
				{{ component.aboveFoldContent }}
			</p>
			<p markdown class="text-lg" *ngIf="showBelowFold">
				{{ component?.belowFoldContent }}
			</p>
		</div>
	</div>
	<div *nzModalFooter class="h-12">
		<fms-toggle-favorite-button
			class="float-left"
			mode="button"
			[componentId]="component.id"
			[actionId]="component?.favoriteAction?.id"
		/>
		<fms-button class="float-right" (click)="closeModal()">
			<span class="mr-2">
				<svg
					class="h-6 opacity-50"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M5 13l4 4L19 7"
					/>
				</svg>
			</span>
			Luk
		</fms-button>
	</div>
</ng-container>
