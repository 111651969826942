import { Injectable, inject } from '@angular/core';

import { tap } from 'rxjs/operators';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as userStore from '@fms/ngx-user';

import { FmsCoreService } from '../services/core.service';

@Injectable()
export class FmsAppUserEffects {
	readonly #actions$ = inject(Actions);
	readonly #coreService = inject(FmsCoreService);

	loadUserFailure$ = createEffect(
		() =>
			this.#actions$.pipe(
				ofType(userStore.loadUserFailure),
				tap((action) => {
					this.#coreService.triggerUserLoadErrorModal(
						action.error,
						'UserEffects'
					);
				})
			),
		{ dispatch: false }
	);
}
