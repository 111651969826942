import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';

import { DefaultSessionComponentFragment } from '@fms/ngx-gql-client';
import { ButtonComponent, ChipComponent } from '@fms/ngx-layout';
import { FmsRoutePath } from '@fms/shared-models';

import { FmsToggleFavoriteBtnComponent } from '../../components/toggle-favorite-button/toggle-favorite-button.component';

export interface FmsAudioModalData {
	component$: Observable<DefaultSessionComponentFragment>;
	audioUrl: string;
	title: string;
	subTitle: string | string[];
	textContentTitle: string;
	relatedCourseSessionId: string;
	allowMarkAsSeenOnClose: boolean;
}

@Component({
	standalone: true,
	selector: 'fms-audio-modal',
	templateUrl: 'audio-modal.component.html',
	styleUrls: ['audio-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		NgFor,
		AsyncPipe,
		NzModalModule,
		NzDividerModule,
		FmsToggleFavoriteBtnComponent,
		ChipComponent,
		ButtonComponent,
	],
})
export class FmsAudioModalComponent {
	readonly modalData = inject<FmsAudioModalData>(NZ_MODAL_DATA);
	readonly #modalRef = inject(NzModalRef);
	readonly #router = inject(Router);
	readonly #routePathEnum = FmsRoutePath;
	showBelowFold = false;

	async closeModal(
		props: { markSeen?: boolean } = { markSeen: true }
	): Promise<void> {
		if (this.modalData.allowMarkAsSeenOnClose) {
			props.markSeen = props?.markSeen ?? true;
		} else {
			props.markSeen = false;
		}
		// Component is already marked as seen
		this.#modalRef.destroy({
			component$: props.markSeen ? this.modalData.component$ : null,
		});
		return;
	}

	toggleBelowFoldContent() {
		this.showBelowFold = !this.showBelowFold;
	}

	isArray(obj: unknown = null) {
		return Array.isArray(obj);
	}

	goToRelatedCourseSession() {
		this.closeModal({ markSeen: false }).then((_result) => {
			this.#router.navigate([
				'/',
				this.#routePathEnum.AppFrame,
				this.#routePathEnum.CourseSession,
				this.modalData.relatedCourseSessionId,
			]);
		});
	}
}
