import { WINDOW } from '@ng-web-apis/common';
import { StorageMap } from '@ngx-pwa/local-storage';
import { format } from 'date-fns';

import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';

import { BehaviorSubject, interval, Observable } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	map,
	switchMap,
	take,
	withLatestFrom,
} from 'rxjs/operators';

import { NzModalService } from 'ng-zorro-antd/modal';

import {
	GQLService,
	MinimalUserActionFragment,
	GetSessionComponentDocument,
	DefaultSessionComponentFragment,
	DefaultCourseSessionFragment,
} from '@fms/ngx-gql-client';
import { UserFacade } from '@fms/ngx-user';
import {
	FmsClientOrganizationId,
	FmsCourseId,
	LANDING_PAGE_SOURCE_STORAGE_KEY,
} from '@fms/shared-models';
import { SessionComponentType, UserActionType } from '@fms/ts-gql-types';

import { environment } from '../../environments/environment';
import { FmsAudioModalComponent } from '../modals/audio-modal/audio-modal.component';
import { FmsAudioModalData } from '../modals/audio-modal/audio-modal.component';
import { FmsTextModalComponent } from '../modals/text-modal/text-modal.component';
import { FmsUserLoadErrorModalComponent } from '../modals/user-load-error-modal/user-load-error-modal.component';
import {
	FmsVideoModalComponent,
	FmsVideoModalData,
} from '../modals/video-modal/video-modal.component';

export interface IQueryParamsForContentModal {
	activeComponentType: SessionComponentType;
}

@Injectable({ providedIn: 'root' })
export class FmsCoreService {
	readonly #userFacade = inject(UserFacade);
	readonly #modalService = inject(NzModalService);
	readonly #gql = inject(GQLService);
	readonly #storage = inject(StorageMap);
	readonly #auth = inject(AuthService);
	readonly #activatedRoute = inject(ActivatedRoute);
	readonly #router = inject(Router);
	readonly #http = inject(HttpClient);
	readonly #windowRef = inject(WINDOW);
	readonly #globalLoadingSubject = new BehaviorSubject<boolean>(false);
	readonly globalLoading$ = interval(250).pipe(
		switchMap(() =>
			this.#globalLoadingSubject.asObservable().pipe(
				debounceTime(100),
				withLatestFrom(this.#auth.isLoading$),
				distinctUntilChanged(),
				map(([status, authLoading]) => {
					return authLoading ? authLoading : status;
				})
			)
		)
	);
	userLoadErrorModalShown = false;
	selectWorkspaceModalShown = false;

	showGlobalLoader() {
		this.#globalLoadingSubject.next(true);
	}

	hideGlobalLoader() {
		this.#globalLoadingSubject.next(false);
	}

	afterContentModalOpenHandler = (queryParams: IQueryParamsForContentModal) => {
		if (queryParams) {
			this.#router.navigate([], {
				relativeTo: this.#activatedRoute,
				queryParams,
				queryParamsHandling: 'merge',
				replaceUrl: true,
			});
		}
	};

	afterContentModalCloseHandler = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data: any, // TODO: Replace any type [MAB]
		queryParams?: IQueryParamsForContentModal
	) => {
		const component$ =
			data?.component$ as Observable<DefaultSessionComponentFragment>;
		if (component$ !== null) {
			this.createSeen(component$);
		}
		if (queryParams) {
			const resetQueryParams = Object.keys(queryParams).reduce((prev, cur) => {
				return { ...prev, [cur]: null };
			}, {});
			this.#router.navigate([], {
				relativeTo: this.#activatedRoute,
				queryParams: resetQueryParams,
				queryParamsHandling: 'merge',
				replaceUrl: true,
			});
		}
	};

	triggerVideoModal(props: {
		title: string;
		subTitle: string | string[];
		videoId: number | string;
		textContentTitle: string;
		component$?: Observable<DefaultSessionComponentFragment>;
		queryParams?: IQueryParamsForContentModal;
		hideFavoriteBtn?: boolean;
	}) {
		let videoId: number;
		if (typeof props.videoId === 'string') {
			videoId = parseInt(props.videoId);
		} else {
			videoId = props.videoId;
		}
		props.hideFavoriteBtn = props?.hideFavoriteBtn ?? false;
		const videoModal = this.#modalService.create<
			FmsVideoModalComponent,
			FmsVideoModalData
		>({
			nzContent: FmsVideoModalComponent,
			nzData: {
				title: props.title,
				subTitle: props.subTitle,
				textContentTitle: props.textContentTitle,
				videoId: videoId,
				component$: props.component$,
				hideFavoriteBtn: props.hideFavoriteBtn,
			},
			nzAutofocus: null,
			nzBodyStyle: { padding: '0' },
			nzCentered: true,
			nzClassName: 'fms-video-modal',
			nzWidth: '', // By passing an empty string, we can let the responsive size handling of .fms-video-modal take control
			nzClosable: false,
		});
		videoModal.afterOpen.subscribe((_data) =>
			this.afterContentModalOpenHandler(props.queryParams)
		);
		videoModal.afterClose.subscribe((data) =>
			this.afterContentModalCloseHandler(data, props.queryParams)
		);
	}

	triggerAudioModal(props: {
		title: string;
		subTitle: string | string[];
		textContentTitle: string;
		audioUrl: string;
		component$?: Observable<DefaultSessionComponentFragment>;
		queryParams?: IQueryParamsForContentModal;
		relatedCourseSessionId?: string;
		allowMarkAsSeenOnClose?: boolean;
	}) {
		const audioModal = this.#modalService.create<
			FmsAudioModalComponent,
			FmsAudioModalData
		>({
			nzContent: FmsAudioModalComponent,
			nzData: {
				title: props.title,
				subTitle: props.subTitle,
				textContentTitle: props.textContentTitle,
				audioUrl: props.audioUrl,
				component$: props.component$,
				relatedCourseSessionId: props?.relatedCourseSessionId ?? undefined,
				allowMarkAsSeenOnClose: props?.allowMarkAsSeenOnClose ?? true,
			},
			nzAutofocus: null,
			nzBodyStyle: { padding: '0' },
			nzCentered: true,
			nzClassName: 'fms-audio-modal',
			nzWidth: '', // By passing an empty string, we can let the responsive size handling of .fms-audio-modal take control
			nzClosable: false,
		});
		audioModal.afterOpen.subscribe((_data) =>
			this.afterContentModalOpenHandler(props.queryParams)
		);
		audioModal.afterClose.subscribe((data) =>
			this.afterContentModalCloseHandler(data, props.queryParams)
		);
	}

	triggerTextModal(props: {
		title: string;
		subTitle: string | string[];
		component$?: Observable<DefaultSessionComponentFragment>;
		queryParams?: IQueryParamsForContentModal;
	}) {
		const textModal = this.#modalService.create<FmsTextModalComponent>({
			nzContent: FmsTextModalComponent,
			nzData: {
				title: props.title,
				subTitle: props.subTitle,
				component$: props.component$,
			},
			nzAutofocus: null,
			nzBodyStyle: { padding: '0' },
			nzCentered: true,
			nzClassName: 'fms-text-modal',
			nzWidth: '', // By passing an empty string, we can let the responsive size handling of .fms-text-modal take control
			nzClosable: false,
		});
		textModal.afterOpen.subscribe((_data) =>
			this.afterContentModalOpenHandler(props.queryParams)
		);
		textModal.afterClose.subscribe((data) =>
			this.afterContentModalCloseHandler(data, props.queryParams)
		);
	}

	async createSeen(component$: Observable<DefaultSessionComponentFragment>) {
		const component = await component$.pipe(take(1)).toPromise();
		if (!component?.seenAction) {
			this.createUserAction({
				actionType: UserActionType.Seen,
				componentId: component.id,
			}).subscribe();
		}
	}

	createUserAction(props: {
		actionType: UserActionType;
		componentId: string;
	}): Observable<MinimalUserActionFragment> {
		return this.#gql.userAction
			.createUserAction(
				{
					data: {
						userId: this.#userFacade.state.userId,
						actionType: props.actionType,
						componentId: props.componentId,
					},
				},
				{
					refetchQueries: [
						{
							query: GetSessionComponentDocument,
							variables: { id: props.componentId },
						},
					],
				}
			)
			.pipe(map((result) => result.data.createUserAction));
	}

	removeUserAction(props: {
		actionId: string;
		componentId: string;
	}): Observable<boolean> {
		return this.#gql.userAction
			.removeUserAction(
				{ id: props.actionId },
				{
					refetchQueries: [
						{
							query: GetSessionComponentDocument,
							variables: { id: props.componentId },
						},
					],
				}
			)
			.pipe(map((result) => result.data.removeUserAction));
	}

	calcCompletenessCount(session: DefaultCourseSessionFragment): number {
		return [
			session.introComponent?.seenAction !== null,
			session.meditationComponent?.seenAction !== null,
			session.otherComponent?.seenAction !== null,
		].filter((elem) => elem).length;
	}

	saveLandingPageSource(courseId: FmsCourseId): Observable<void> {
		return this.#storage.set(LANDING_PAGE_SOURCE_STORAGE_KEY, courseId);
	}

	getLandingPageSource(): Observable<FmsCourseId> {
		return this.#storage.get(LANDING_PAGE_SOURCE_STORAGE_KEY, {
			type: 'string',
		}) as Observable<FmsCourseId>;
	}

	triggerUserLoadErrorModal(error: Error, _src?: unknown) {
		if (this.userLoadErrorModalShown) {
			return;
		}
		this.userLoadErrorModalShown = true;
		const modalRef = this.#modalService.create({
			nzContent: FmsUserLoadErrorModalComponent,
			nzData: { error },
			nzCentered: true,
			nzClosable: false,
			nzMaskClosable: false,
			nzKeyboard: false,
			nzFooter: null,
		});
		modalRef.afterOpen.subscribe(() => this.hideGlobalLoader());
		modalRef.afterClose.subscribe((result) => {
			this.showGlobalLoader();
			this.userLoadErrorModalShown = false;
			if (result?.reloadUser) {
				this.#userFacade.setupUserLoad();
			}
			if (result?.reset) {
				this.#userFacade.logout();
			}
		});
	}

	isAdminUser(): Observable<boolean> {
		return this.#http.get<boolean>(`${environment.apiUrl}/admin/is-admin`);
	}

	getEnrolmentExtract(query: {
		startDate: Date;
		endDate: Date;
	}): Observable<true> {
		const httpParams = new HttpParams({
			fromObject: {
				startDate: format(query.startDate, 'yyyy-MM-dd'),
				endDate: format(query.endDate, 'yyyy-MM-dd'),
			},
		});
		return this.#http
			.get<BlobPart>(`${environment.apiUrl}/admin/enrolment-extract`, {
				params: httpParams,
				responseType: 'blob' as 'json', // There's a bug in the typing
			})
			.pipe(
				map((data) => {
					const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
					const downloadURL = URL.createObjectURL(blob);
					const link = this.#windowRef.document.createElement('a');
					link.href = downloadURL;
					link.download = `fms-enrolments-${format(
						new Date(),
						'yyyy-MM-dd-HH-mm'
					)}.xlsx`;
					link.click();
					URL.revokeObjectURL(downloadURL);
					return true;
				})
			);
	}

	getAdherenceExtract(params: {
		courseId: FmsCourseId;
		orgIds: FmsClientOrganizationId[];
	}): Observable<true> {
		const httpParams = new HttpParams({
			fromObject: params,
		});
		return this.#http
			.get<BlobPart>(`${environment.apiUrl}/admin/adherence-extract`, {
				params: httpParams,
				responseType: 'blob' as 'json', // There's a bug in the typing
			})
			.pipe(
				map((data) => {
					const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
					const downloadURL = URL.createObjectURL(blob);
					const link = this.#windowRef.document.createElement('a');
					link.href = downloadURL;
					link.download = `fms-adherence-${format(
						new Date(),
						'yyyy-MM-dd-HH-mm'
					)}.xlsx`;
					link.click();
					URL.revokeObjectURL(downloadURL);
					return true;
				})
			);
	}

	markOnboardingCompleted(enrolmentId) {
		this.#userFacade.updateState({ curCourseOnboardingCompleted: true });
		this.#gql.enrolment
			.updateEnrolment({
				data: { id: enrolmentId, onboardingCompleted: true },
			})
			.subscribe();
	}
}
