import { Options } from '@vimeo/player';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
	IVimeoApiResponse,
	IVimeoThumbnail,
	IVimeoVideoInfo,
	VIMEO_CLIENT_ID,
	VIMEO_CLIENT_SECRET,
} from '../constants';

@Injectable({ providedIn: 'root' })
export class FmsVimeoService {
	public defaultPlayerOptions: Options = {
		responsive: true,
		texttrack: 'da',
		dnt: true,
	};
	thumbnails: { [key: string]: IVimeoThumbnail } = {};
	videoInfo: { [key: string]: IVimeoVideoInfo } = {};
	constructor(
		private http: HttpClient,
		@Inject(VIMEO_CLIENT_ID) private clientId,
		@Inject(VIMEO_CLIENT_SECRET) private clientSecret
	) {}

	getVideoThumbnail(videoId: string): Observable<IVimeoThumbnail> {
		if (this.thumbnails.hasOwnProperty(videoId)) {
			return of(this.thumbnails[videoId]);
		}
		return this.http
			.get<IVimeoApiResponse<IVimeoThumbnail[]>>(
				`https://api.vimeo.com/videos/${videoId}/pictures?page=1&per_page=5`,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						Authorization: `basic ${btoa(
							this.clientId + ':' + this.clientSecret
						)}`,
					}),
				}
			)
			.pipe(
				map((result) => result?.data.find((elem) => elem?.active)),
				tap((result) => (this.thumbnails[videoId] = result))
			);
	}

	updateVideoInfo(id: string | number, info: Partial<IVimeoVideoInfo>) {
		const existingData = this.videoInfo[id] ?? {};
		const updatedData = { ...existingData, ...info };
		this.videoInfo[id] = updatedData;
	}
}
