import { WINDOW } from '@ng-web-apis/common';

import { NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	inject,
} from '@angular/core';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzResultModule } from 'ng-zorro-antd/result';

import { UserFacade } from '@fms/ngx-user';

export enum UserLoadErrorType {
	Unknown = 'Unknown',
	EmailNotWhitelisted = 'EmailNotWhitelisted',
	EmailNotVerified = 'EmailNotVerified',
	AccessDenied = 'AccessDenied',
}

export interface IUserLoadError {
	errorType: UserLoadErrorType;
	error: Error & { [key: string]: unknown };
	title: string;
	subTitle: string;
	status: 'success' | 'error' | 'info' | 'warning';
	allowPageRefresh: boolean;
	allowResetSession: boolean;
	allowReloadUser: boolean;
}

@Component({
	standalone: true,
	selector: 'fms-user-load-error-modal',
	templateUrl: 'user-load-error-modal.component.html',
	styleUrls: ['user-load-error-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, NzModalModule, NzButtonModule, NzResultModule],
})
export class FmsUserLoadErrorModalComponent implements OnInit {
	readonly error = inject<{ error: Error }>(NZ_MODAL_DATA).error;
	readonly #modal = inject(NzModalRef);
	readonly #userFacade = inject(UserFacade);
	readonly #windowRef = inject(WINDOW);
	errorDetails: IUserLoadError;

	ngOnInit() {
		this.errorDetails = this.getErrorDetails(this.error);
	}

	retry() {
		this.#modal.destroy({ reloadUser: true });
	}

	reset() {
		this.#modal.destroy({ reset: true });
	}

	refetchPage() {
		this.#windowRef.location.reload();
	}

	private getErrorDetails(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		error: Error & { [key: string]: any } // TODO: Solve this ESLint issue [MAB]
	): IUserLoadError {
		const errorCode = error?.error_description ?? error?.message;
		switch (errorCode) {
			case 'Please verify your email before logging in':
				return {
					errorType: UserLoadErrorType.EmailNotVerified,
					error,
					status: 'info',
					title: 'Bekræft venligst email-adresse',
					subTitle: `Vi har sendt en email til ${
						this.#userFacade.state?.email ?? 'den email-adresse'
					} som du tilmeldte dig med, så du kan bekræfte at den er din. Efter at du har bekræftet dette, kan du blot genhente denne side.`,
					allowPageRefresh: true,
					allowReloadUser: false,
					allowResetSession: false,
				};
			case 'User did not authorize the request':
				return {
					errorType: UserLoadErrorType.AccessDenied,
					error,
					status: 'error',
					title: 'Du nægtede Åben Og Rolig adgang til din bruger',
					subTitle:
						'Hvis du ønsker at bruge Åben og Rolig, genhent da siden og giv Åben og Rolig tilladelse til at tilgå din bruger-profil',
					allowPageRefresh: true,
					allowReloadUser: false,
					allowResetSession: false,
				};
			default:
				return {
					errorType: UserLoadErrorType.Unknown,
					error,
					status: 'error',
					title: 'Fejl ved indlæsning af bruger',
					subTitle:
						'Prøv enten igen eller nulstil din login session. Hvis problemet fortsætter, kontakt da venligst support på info@fondenmentalsundhed.dk.',
					allowPageRefresh: true,
					allowReloadUser: false,
					allowResetSession: false,
				};
		}
	}
}
