import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { UserEffects } from './+state/user/user.effects';
import * as fromUser from './+state/user/user.reducer';
import {
	COURSE_ROUTE,
	CAPACITOR_LOGOUT_RETURN_TO,
	IS_CAPACITOR_APP,
	AUTH0_CLIENT_ID,
} from './constants';
import { UserFacade } from './user.facade';

export interface IUserModuleConfig {
	courseRoute: string[];
	auth0ClientId: string;
	capacitorLogoutReturnTo: string;
	isCapacitorApp: boolean;
}

@NgModule({
	imports: [CommonModule],
	providers: [UserFacade],
})
export class FmsNgxUserStateModule {
	static forRoot(
		config: IUserModuleConfig
	): ModuleWithProviders<FmsNgxUserStateModule> {
		return {
			ngModule: FmsNgxUserStateModule,
			providers: [
				provideState(fromUser.USER_FEATURE_KEY, fromUser.reducer),
				provideEffects([UserEffects]),
				UserFacade,
				{
					provide: COURSE_ROUTE,
					useValue: config.courseRoute,
				},
				{
					provide: CAPACITOR_LOGOUT_RETURN_TO,
					useValue: config.capacitorLogoutReturnTo,
				},
				{
					provide: IS_CAPACITOR_APP,
					useValue: config.isCapacitorApp,
				},
				{
					provide: AUTH0_CLIENT_ID,
					useValue: config.auth0ClientId,
				},
			],
		};
	}
	static forChild(): ModuleWithProviders<FmsNgxUserStateModule> {
		/**
		 * We want a singleton instance of LayoutService,
		 * therefore we do not provide it when forChild is called
		 * (which is done by lazy loaded modules)
		 * See https://stackoverflow.com/a/49878339
		 */
		return {
			ngModule: FmsNgxUserStateModule,
			providers: [],
		};
	}
}
