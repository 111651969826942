import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	standalone: true,
	selector: 'fms-breadcrumb-item',
	templateUrl: 'breadcrumb-item.component.html',
	styleUrls: ['breadcrumb-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RouterLink, RouterLinkActive],
})
export class BreadcrumbItemComponent {
	@Input({ required: true }) itemName!: string;
	@Input() itemPath: string | null = null;
}
