import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateFn,
	Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NzMessageService } from 'ng-zorro-antd/message';

import { FmsRoutePath } from '@fms/shared-models';

import { FmsCoreService } from '../services/core.service';

export const isAdminGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Observable<boolean> => {
	const core = inject(FmsCoreService);
	const router = inject(Router);
	const messageService = inject(NzMessageService);
	return core.isAdminUser().pipe(
		tap((isAdmin) => {
			if (!isAdmin) {
				router
					.navigate(['/', FmsRoutePath.NotFound])
					.then(() => messageService.error('Ingen adgang'));
			}
		})
	);
};
