/**
 * @remarks
 *
 * enum with the paths used in the Fms app router
 */
export enum FmsRoutePath {
	AppFrame = 'content',
	AdminDashboard = 'admin',
	Dashboard = 'home',
	LandingPage = 'front',
	Onboarding = 'onboarding',
	CourseSession = 'course-session',
	KnowledgeCenter = 'knowledge-center',
	Favorites = 'favorites',
	MeditationListing = 'meditation-listing',
	AppWelcome = 'app-welcome',
	NotFound = '404',
	AuthCallback = 'auth-callback',
}
