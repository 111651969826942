import Player, { Options } from '@vimeo/player';

import { NgIf } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	Output,
	ViewChild,
	inject,
} from '@angular/core';

import { FmsVimeoService } from '../../services/vimeo.service';

export interface IFmsVimeoOptions extends Omit<Options, 'id'> {
	id: string | number;
}

@Component({
	standalone: true,
	selector: 'fms-vimeo-player',
	templateUrl: 'vimeo-player.component.html',
	styleUrls: ['vimeo-player.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf],
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit {
	@ViewChild('playerContainer') playerContainer: ElementRef;
	@Optional() @Input() classes: string[] = [];
	@Input() playerId: string | number;
	@Input() playerOptions: IFmsVimeoOptions;
	@Output() playerReady = new EventEmitter<boolean>();
	private vimeoService = inject(FmsVimeoService);
	validOptions!: boolean;

	ngOnInit() {
		this.validOptions =
			![undefined, null, ''].includes(this.playerOptions?.id?.toString()) ||
			![undefined, null, ''].includes(this.playerOptions?.url);
	}

	ngAfterViewInit() {
		if (this.validOptions) {
			const player = new Player(this.playerContainer.nativeElement, {
				...this.vimeoService.defaultPlayerOptions,
				...this.playerOptions,
				id:
					typeof this.playerOptions.id === 'string'
						? parseInt(this.playerOptions.id)
						: this.playerOptions.id,
			});
			player.ready().then(() => this.playerReady.emit(true));
			player.getDuration().then((duration) => {
				this.vimeoService.updateVideoInfo(this.playerOptions.id, { duration });
			});
		}
	}
}
