<div
	*ngIf="cardIconSrc; else coreCard"
	class="card-with-icon flex flex-1 flex-col"
>
	<div class="flow-root flex-col items-center rounded-lg">
		<div class="relative -mb-10 items-center text-center">
			<span *ngIf="leftSlot" class="absolute bottom-0 left-6">
				<ng-container *ngTemplateOutlet="leftSlot" />
			</span>
			<span class="inline-flex justify-center rounded-md bg-transparent">
				<img [class]="cardIconClasses" [src]="cardIconSrc" />
			</span>
			<span *ngIf="rightSlot" class="absolute bottom-0 right-6">
				<ng-container *ngTemplateOutlet="rightSlot" />
			</span>
			<span class="h-10"></span>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="coreCard" />
</div>

<ng-template #coreCard>
	<div
		class="flex flex-1 flex-col overflow-hidden rounded-lg bg-white shadow"
	>
		<ng-content select="fms-card-title" />
		<ng-content select="fms-card-body" />
		<ng-content select="fms-card-footer" />
	</div>
</ng-template>
