/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';

export type MinimalMediaElementFragment = {
	__typename: 'FmsMediaElement';
	id: string;
	title?: string | null;
	description?: string | null;
	mediaType: SchemaTypes.MediaType;
	duration?: number | null;
	srcProvider: SchemaTypes.FmsMediaProvider;
	srcId?: string | null;
	srcUrl?: string | null;
};

export type DefaultMediaElementFragment = {
	__typename: 'FmsMediaElement';
	id: string;
	title?: string | null;
	description?: string | null;
	mediaType: SchemaTypes.MediaType;
	duration?: number | null;
	srcProvider: SchemaTypes.FmsMediaProvider;
	srcId?: string | null;
	srcUrl?: string | null;
};

export type GetMediaElementQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetMediaElementQuery = {
	__typename?: 'Query';
	mediaElement: {
		__typename: 'FmsMediaElement';
		id: string;
		title?: string | null;
		description?: string | null;
		mediaType: SchemaTypes.MediaType;
		duration?: number | null;
		srcProvider: SchemaTypes.FmsMediaProvider;
		srcId?: string | null;
		srcUrl?: string | null;
	};
};

export const MinimalMediaElementFragmentDoc = gql`
	fragment minimalMediaElement on FmsMediaElement {
		__typename
		id
		title
		description
		mediaType
		duration
		srcProvider
		srcId
		srcUrl
	}
`;
export const DefaultMediaElementFragmentDoc = gql`
	fragment defaultMediaElement on FmsMediaElement {
		...minimalMediaElement
	}
	${MinimalMediaElementFragmentDoc}
`;
export const GetMediaElementDocument = gql`
	query getMediaElement($id: ID!) {
		mediaElement(id: $id) {
			...defaultMediaElement
		}
	}
	${DefaultMediaElementFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetMediaElementGQL extends Apollo.Query<
	GetMediaElementQuery,
	GetMediaElementQueryVariables
> {
	document = GetMediaElementDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class MediaElementGQLService {
	constructor(private getMediaElementGql: GetMediaElementGQL) {}

	getMediaElement(
		variables: GetMediaElementQueryVariables,
		options?: QueryOptionsAlone<GetMediaElementQueryVariables>
	) {
		return this.getMediaElementGql.fetch(variables, options);
	}

	getMediaElementWatch(
		variables: GetMediaElementQueryVariables,
		options?: WatchQueryOptionsAlone<GetMediaElementQueryVariables>
	) {
		return this.getMediaElementGql.watch(variables, options);
	}
}
