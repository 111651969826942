import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	CanActivateFn,
} from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';

import { Observable, of, combineLatest, interval, race } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';

import { FmsRoutePath } from '@fms/shared-models';

/**
 * Based on https://github.com/auth0/auth0-angular/blob/master/projects/auth0-angular/src/lib/auth.guard.ts
 *
 * But with added handling of permission denied in login
 */
export const isNotAuthenticatedGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot
): Observable<boolean> => {
	const auth0 = inject(AuthService);
	const router = inject(Router);
	return combineLatest([
		auth0.isAuthenticated$.pipe(take(1)),
		race(auth0.error$, interval(20).pipe(map(() => null))),
	]).pipe(
		map(([loggedIn, error]) => {
			return { loggedIn, error };
		}),
		switchMap((result) => {
			if (result.loggedIn) {
				router.navigate(['/', FmsRoutePath.AppFrame]);
				return of(false);
			} else {
				return of(true);
			}
		})
	);
};
