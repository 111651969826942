import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	standalone: true,
	selector: 'fms-breadcrumbs',
	templateUrl: 'page-breadcrumbs.component.html',
	styleUrls: ['page-breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, RouterLink, RouterLinkActive],
})
export class PageBreadcrumbsComponent {
	@Input() showHome = true;
	@Input() homePath: string[] | null = null;
}
