import { InjectionToken } from '@angular/core';

export interface IVimeoApiResponse<T> {
	data: T;
	page: number;
	paging: {
		first: string;
		last: string;
		next: string;
		previous: string;
	};
	per_page: number;
	total: number;
}

export interface IVimeoThumbnail {
	/**
	 * Whether the picture is currently active.
	 */
	active: boolean;
	/**
	 * Whether the picture is Vimeo's default
	 */
	default_picture: boolean;
	/**
	 * The upload URL of the picture. This field appears upon the initial creation of a picture resource.
	 */
	link: string;
	/**
	 * The resource key string of the picture.
	 */
	resource_key: string;
	/**
	 * An array containing reference information about all available image files.
	 */
	sizes: {
		/**
		 * The height of the picture
		 */
		height: number;
		/**
		 * The direct link to the image file.
		 */
		link: string;
		/**
		 * The direct link to the image file with a play button overlay.
		 */
		link_with_play_button: string;
		/**
		 * The width of the picture.
		 */
		width: number;
	}[];
	/**
	 * The type of the picture
	 */
	type:
		| 'caution' // The picture isn't appropriate for all ages
		| 'custom' // The picture is a custom video image
		| 'default'; // The picture is the default video image
	/**
	 * The URI of the picture
	 */
}

export interface IVimeoModuleConfig {
	clientId: string;
	clientSecret: string;
}

export const VIMEO_CLIENT_ID = new InjectionToken<string[]>('VIMEO_CLIENT_ID');

export const VIMEO_CLIENT_SECRET = new InjectionToken<string[]>(
	'VIMEO_CLIENT_SECRET'
);

export interface IVimeoVideoInfo {
	duration?: number;
}
