import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	inject,
} from '@angular/core';
import { Routes, Router, RouterOutlet } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';

import { mergeMap, of } from 'rxjs';

import { NzSpinModule } from 'ng-zorro-antd/spin';

import { FmsCourseId, FmsRoutePath } from '@fms/shared-models';

import { environment } from '../environments/environment';
import { isAdminGuard } from './guards/is-admin.guard';
import { isAuthenticatedGuard } from './guards/is-authenticated.guard';
import { isNotAuthenticatedGuard } from './guards/is-not-authenticated.guard';
import { isNotEnrolledUserGuard } from './guards/is-not-enrolled-user.guard';
import { FmsCoreService } from './services/core.service';

// eslint-disable-next-line @angular-eslint/prefer-standalone-component
@Component({
	standalone: true,
	selector: 'fms-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AsyncPipe, RouterOutlet, NzSpinModule],
})
export class AppComponent implements OnInit {
	readonly #auth = inject(AuthService);
	readonly #router = inject(Router);
	readonly #core = inject(FmsCoreService);
	readonly globalLoading$ = this.#core.globalLoading$;

	ngOnInit(): void {
		// Hide the splash
		if (environment.envName === 'capacitor') {
			SplashScreen.hide();
		}

		// Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
		App.addListener('appUrlOpen', ({ url }) => {
			console.log('appUrlOpen', url);
			if (url?.startsWith(environment.auth0.redirectUri)) {
				// If the URL is an authentication callback URL..
				if (
					url.includes('state=') &&
					(url.includes('error=') || url.includes('code='))
				) {
					// Call handleRedirectCallback and close the browser
					this.#core.showGlobalLoader();
					this.#auth
						.handleRedirectCallback(url)
						.pipe(
							mergeMap(() => {
								if (Capacitor.getPlatform() === 'ios') {
									return Browser.close();
								} else {
									return of(null);
								}
							})
						)
						.subscribe(() => {
							this.#router.navigate(['/', FmsRoutePath.AuthCallback]);
							this.#core.hideGlobalLoader();
						});
				} else {
					if (Capacitor.getPlatform() === 'ios') {
						Browser.close();
					}
				}
			}
		});
	}
}

const envDependentRoutes: Routes =
	environment.envName !== 'capacitor'
		? [
				// Used when app is desktop version
				{
					path: '',
					pathMatch: 'full',
					data: { associatedCourse: FmsCourseId.Citizen },
					loadComponent: () =>
						import('./pages/landing-page/landing-page.component').then(
							(m) => m.FmsLandingPageComponent
						),
				},
				{
					path: 'arbejdsliv',
					pathMatch: 'full',
					data: { associatedCourse: FmsCourseId.Worklife },
					loadComponent: () =>
						import('./pages/landing-page/landing-page.component').then(
							(m) => m.FmsLandingPageComponent
						),
				},
				{
					path: 'app-welcome',
					pathMatch: 'full',
					loadComponent: () =>
						import(
							'./pages/capacitor-welcome-page/capacitor-welcome-page.component'
						).then((m) => m.FmsCapacitorWelcomePageComponent),
				},
		  ]
		: [
				// Used when app is in Capacitor mode
				{
					path: '',
					pathMatch: 'full',
					canActivate: [isNotAuthenticatedGuard],
					loadComponent: () =>
						import(
							'./pages/capacitor-welcome-page/capacitor-welcome-page.component'
						).then((m) => m.FmsCapacitorWelcomePageComponent),
				},
		  ];

export const FMS_APP_ROUTES: Routes = [
	{
		path: FmsRoutePath.AppFrame,
		canActivateChild: [isAuthenticatedGuard], // All routes in this object require authentication
		loadChildren: () =>
			import('./app-frame/app-frame.component').then((c) => c.APP_FRAME_ROUTES),
	},
	{
		path: FmsRoutePath.AdminDashboard,
		canActivateChild: [isAuthenticatedGuard, isAdminGuard],
		loadComponent: () =>
			import(
				'./pages/admin-dashboard-page/admin-dashboard-page.component'
			).then((m) => m.FmsAdminDashboardPageComponent),
	},
	{
		path: FmsRoutePath.Onboarding,
		pathMatch: 'full',
		canActivate: [isAuthenticatedGuard, isNotEnrolledUserGuard],
		loadComponent: () =>
			import('./pages/onboarding-page/onboarding-page.component').then(
				(m) => m.FmsOnboardingPageComponent
			),
	},
	{
		path: FmsRoutePath.AuthCallback,
		pathMatch: 'full',
		loadComponent: () =>
			import('./components/auth-callback/auth-callback.component').then(
				(m) => m.FmsAuthCallbackComponent
			),
	},
	{
		path: FmsRoutePath.NotFound,
		loadComponent: () =>
			import('./pages/not-found-page/not-found-page.component').then(
				(m) => m.FmsNotFoundPageComponent
			),
	},
	...envDependentRoutes,
	{
		path: '**',
		redirectTo: FmsRoutePath.NotFound,
	},
];
