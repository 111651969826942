import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';

import { DefaultSessionComponentFragment } from '@fms/ngx-gql-client';
import { ButtonComponent, ChipComponent } from '@fms/ngx-layout';
import { FmsNgxVimeoModule } from '@fms/ngx-vimeo';

import { FmsToggleFavoriteBtnComponent } from '../../components/toggle-favorite-button/toggle-favorite-button.component';

export interface FmsVideoModalData {
	component$: Observable<DefaultSessionComponentFragment>;
	videoId: number;
	title: string;
	subTitle: string | string[];
	textContentTitle: string;
	hideFavoriteBtn: boolean;
}

@Component({
	standalone: true,
	selector: 'fms-video-modal',
	templateUrl: 'video-modal.component.html',
	styleUrls: ['video-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		NgFor,
		NgTemplateOutlet,
		AsyncPipe,
		NzModalModule,
		NzDividerModule,
		FmsNgxVimeoModule,
		FmsToggleFavoriteBtnComponent,
		ChipComponent,
		ButtonComponent,
	],
})
export class FmsVideoModalComponent {
	readonly modalData = inject<FmsVideoModalData>(NZ_MODAL_DATA);
	readonly #modalRef = inject(NzModalRef);
	showBelowFold = false;

	toggleBelowFoldContent() {
		this.showBelowFold = !this.showBelowFold;
	}

	async closeModal(): Promise<void> {
		// Component is already marked as seen
		this.#modalRef.destroy({ component$: this.modalData.component$ });
		return;
	}

	isArray(obj: unknown = null) {
		return Array.isArray(obj);
	}
}
