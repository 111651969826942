import { NgModule } from '@angular/core';

import { InMemoryCache } from '@apollo/client/core';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { environment } from '../environments/environment';

const uri = environment.apiUrl + environment.graphQlRoute;
export function createApollo(httpLink: HttpLink) {
	return {
		link: httpLink.create({ uri }),
		cache: new InMemoryCache({
			addTypename: true,
		}),
		connectToDevTools: true,
		addTypename: true,
	};
}

@NgModule({
	imports: [ApolloModule],
	exports: [],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink],
		},
	],
})
export class FmsGraphQLModule {}
