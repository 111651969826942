import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'fms-button',
	templateUrl: 'button.component.html',
	styleUrls: ['button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class ButtonComponent {
	@Input() disabled = false;
	@Input() type: 'default' | 'secondary' | 'primary' = 'default';
}
