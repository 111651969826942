import { Injectable, Inject, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Apollo } from 'apollo-angular';

import { AuthService } from '@auth0/auth0-angular';

import { tap } from 'rxjs/operators';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { COURSE_ROUTE, IS_CAPACITOR_APP } from '../../constants';
import { UserFacade } from '../../user.facade';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
	private actions$ = inject(Actions);
	private router = inject(Router);
	private apollo = inject(Apollo);
	private courseRoute = inject(COURSE_ROUTE);
	private isCapacitorApp = inject(IS_CAPACITOR_APP);
	private auth0 = inject(AuthService);
	private userFacade = inject(UserFacade);

	changeCourse$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UserActions.changeCourse),
				tap(() => {
					this.apollo.client
						.resetStore()
						.then((_result) => this.router.navigate(this.courseRoute));
				})
			),
		{ dispatch: false }
	);

	logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UserActions.logout),
				tap((_result) => {
					// Stop notifications poll
					// Reset apollo store
					this.apollo.client.resetStore();
					// Logout with auth0
					if (this.isCapacitorApp) {
						this.userFacade.initiateCapacitorLogout();
					} else {
						this.auth0.logout();
					}
				})
			),
		{ dispatch: false }
	);
}
