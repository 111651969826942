/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';
import { MinimalMediaElementFragmentDoc } from '../media-element/media-element.service';

export type MinimalUserActionFragment = {
	__typename: 'FmsUserAction';
	id: string;
	actionType: SchemaTypes.UserActionType;
	createdAt: any;
	updatedAt: any;
	user: { __typename?: 'FmsUser'; id: string };
	component: { __typename?: 'FmsSessionComponent'; id: string };
};

export type UserFavoriteActionFragment = {
	__typename: 'FmsUserAction';
	id: string;
	actionType: SchemaTypes.UserActionType;
	createdAt: any;
	updatedAt: any;
	component: {
		__typename?: 'FmsSessionComponent';
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		courseSession: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
			description?: string | null;
			type: SchemaTypes.CourseSessionType;
			theme: SchemaTypes.CourseSessionTheme;
			themeNumber?: number | null;
			themeSessionNumber: number;
			course?: {
				__typename?: 'FmsCourseSession';
				id: string;
				title?: string | null;
			} | null;
		};
	};
	user: { __typename?: 'FmsUser'; id: string };
};

export type GetUserActionQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetUserActionQuery = {
	__typename?: 'Query';
	userAction: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	};
};

export type GetUserFavoritesQueryVariables = SchemaTypes.Exact<{
	[key: string]: never;
}>;

export type GetUserFavoritesQuery = {
	__typename?: 'Query';
	userFavorites: Array<{
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		component: {
			__typename?: 'FmsSessionComponent';
			id: string;
			title?: string | null;
			description?: string | null;
			componentType: SchemaTypes.SessionComponentType;
			aboveFoldContent?: string | null;
			mediaElement?: {
				__typename: 'FmsMediaElement';
				id: string;
				title?: string | null;
				description?: string | null;
				mediaType: SchemaTypes.MediaType;
				duration?: number | null;
				srcProvider: SchemaTypes.FmsMediaProvider;
				srcId?: string | null;
				srcUrl?: string | null;
			} | null;
			courseSession: {
				__typename?: 'FmsCourseSession';
				id: string;
				title?: string | null;
				description?: string | null;
				type: SchemaTypes.CourseSessionType;
				theme: SchemaTypes.CourseSessionTheme;
				themeNumber?: number | null;
				themeSessionNumber: number;
				course?: {
					__typename?: 'FmsCourseSession';
					id: string;
					title?: string | null;
				} | null;
			};
		};
		user: { __typename?: 'FmsUser'; id: string };
	}>;
};

export type CreateUserActionMutationVariables = SchemaTypes.Exact<{
	data: SchemaTypes.NewFmsUserActionInput;
}>;

export type CreateUserActionMutation = {
	__typename?: 'Mutation';
	createUserAction: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	};
};

export type RemoveUserActionMutationVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type RemoveUserActionMutation = {
	__typename?: 'Mutation';
	removeUserAction: boolean;
};

export const MinimalUserActionFragmentDoc = gql`
	fragment minimalUserAction on FmsUserAction {
		__typename
		id
		user {
			id
		}
		component {
			id
		}
		actionType
		createdAt
		updatedAt
	}
`;
export const UserFavoriteActionFragmentDoc = gql`
	fragment userFavoriteAction on FmsUserAction {
		...minimalUserAction
		component {
			id
			title
			description
			componentType
			aboveFoldContent
			mediaElement {
				...minimalMediaElement
			}
			courseSession {
				id
				title
				description
				type
				theme
				themeNumber
				themeSessionNumber
				course {
					id
					title
				}
			}
		}
	}
	${MinimalUserActionFragmentDoc}
	${MinimalMediaElementFragmentDoc}
`;
export const GetUserActionDocument = gql`
	query getUserAction($id: ID!) {
		userAction(id: $id) {
			...minimalUserAction
		}
	}
	${MinimalUserActionFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetUserActionGQL extends Apollo.Query<
	GetUserActionQuery,
	GetUserActionQueryVariables
> {
	document = GetUserActionDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const GetUserFavoritesDocument = gql`
	query getUserFavorites {
		userFavorites {
			...userFavoriteAction
		}
	}
	${UserFavoriteActionFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetUserFavoritesGQL extends Apollo.Query<
	GetUserFavoritesQuery,
	GetUserFavoritesQueryVariables
> {
	document = GetUserFavoritesDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const CreateUserActionDocument = gql`
	mutation createUserAction($data: NewFmsUserActionInput!) {
		createUserAction(data: $data) {
			...minimalUserAction
		}
	}
	${MinimalUserActionFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class CreateUserActionGQL extends Apollo.Mutation<
	CreateUserActionMutation,
	CreateUserActionMutationVariables
> {
	document = CreateUserActionDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const RemoveUserActionDocument = gql`
	mutation removeUserAction($id: ID!) {
		removeUserAction(id: $id)
	}
`;

@Injectable({
	providedIn: 'root',
})
export class RemoveUserActionGQL extends Apollo.Mutation<
	RemoveUserActionMutation,
	RemoveUserActionMutationVariables
> {
	document = RemoveUserActionDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
	extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class UserActionGQLService {
	constructor(
		private getUserActionGql: GetUserActionGQL,
		private getUserFavoritesGql: GetUserFavoritesGQL,
		private createUserActionGql: CreateUserActionGQL,
		private removeUserActionGql: RemoveUserActionGQL
	) {}

	getUserAction(
		variables: GetUserActionQueryVariables,
		options?: QueryOptionsAlone<GetUserActionQueryVariables>
	) {
		return this.getUserActionGql.fetch(variables, options);
	}

	getUserActionWatch(
		variables: GetUserActionQueryVariables,
		options?: WatchQueryOptionsAlone<GetUserActionQueryVariables>
	) {
		return this.getUserActionGql.watch(variables, options);
	}

	getUserFavorites(
		variables?: GetUserFavoritesQueryVariables,
		options?: QueryOptionsAlone<GetUserFavoritesQueryVariables>
	) {
		return this.getUserFavoritesGql.fetch(variables, options);
	}

	getUserFavoritesWatch(
		variables?: GetUserFavoritesQueryVariables,
		options?: WatchQueryOptionsAlone<GetUserFavoritesQueryVariables>
	) {
		return this.getUserFavoritesGql.watch(variables, options);
	}

	createUserAction(
		variables: CreateUserActionMutationVariables,
		options?: MutationOptionsAlone<
			CreateUserActionMutation,
			CreateUserActionMutationVariables
		>
	) {
		return this.createUserActionGql.mutate(variables, options);
	}

	removeUserAction(
		variables: RemoveUserActionMutationVariables,
		options?: MutationOptionsAlone<
			RemoveUserActionMutation,
			RemoveUserActionMutationVariables
		>
	) {
		return this.removeUserActionGql.mutate(variables, options);
	}
}
