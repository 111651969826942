import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { VimeoPlayerComponent } from './components/vimeo-player.component/vimeo-player.component';
import { VimeoPreviewComponent } from './components/vimeo-preview.component/vimeo-preview.component';
import {
	IVimeoModuleConfig,
	VIMEO_CLIENT_ID,
	VIMEO_CLIENT_SECRET,
} from './constants';
import { FmsVimeoService } from './services/vimeo.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		VimeoPlayerComponent,
		VimeoPreviewComponent,
	],
	exports: [VimeoPlayerComponent, VimeoPreviewComponent],
})
export class FmsNgxVimeoModule {
	static forRoot(
		config: IVimeoModuleConfig
	): ModuleWithProviders<FmsNgxVimeoModule> {
		return {
			ngModule: FmsNgxVimeoModule,
			providers: [
				FmsVimeoService,
				{
					provide: VIMEO_CLIENT_ID,
					useValue: config.clientId,
				},
				{
					provide: VIMEO_CLIENT_SECRET,
					useValue: config.clientSecret,
				},
			],
		};
	}
	static forChild(): ModuleWithProviders<FmsNgxVimeoModule> {
		/**
		 * We want a singleton instance of FmsVimeoService,
		 * therefore we do not provide it when forChild is called
		 * (which is done by lazy loaded modules)
		 * See https://stackoverflow.com/a/49878339
		 */
		return {
			ngModule: FmsNgxVimeoModule,
			providers: [],
		};
	}
}
