/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';

export type MinimalEnrolmentFragment = {
	__typename: 'FmsEnrolment';
	id: string;
	isActive: boolean;
	onboardingCompleted: boolean;
	status: SchemaTypes.EnrolmentStatus;
	startDate: any;
	stopDate?: any | null;
	user: { __typename?: 'FmsUser'; id: string };
	course: { __typename?: 'FmsCourse'; id: string };
};

export type GetEnrolmentQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetEnrolmentQuery = {
	__typename?: 'Query';
	enrolment: {
		__typename: 'FmsEnrolment';
		id: string;
		isActive: boolean;
		onboardingCompleted: boolean;
		status: SchemaTypes.EnrolmentStatus;
		startDate: any;
		stopDate?: any | null;
		user: { __typename?: 'FmsUser'; id: string };
		course: { __typename?: 'FmsCourse'; id: string };
	};
};

export type CreateEnrolmentMutationVariables = SchemaTypes.Exact<{
	data: SchemaTypes.NewFmsEnrolmentInput;
}>;

export type CreateEnrolmentMutation = {
	__typename?: 'Mutation';
	createEnrolment: {
		__typename: 'FmsEnrolment';
		id: string;
		isActive: boolean;
		onboardingCompleted: boolean;
		status: SchemaTypes.EnrolmentStatus;
		startDate: any;
		stopDate?: any | null;
		user: { __typename?: 'FmsUser'; id: string };
		course: { __typename?: 'FmsCourse'; id: string };
	};
};

export type UpdateEnrolmentMutationVariables = SchemaTypes.Exact<{
	data: SchemaTypes.UpdateFmsEnrolmentInput;
}>;

export type UpdateEnrolmentMutation = {
	__typename?: 'Mutation';
	updateEnrolment: {
		__typename: 'FmsEnrolment';
		id: string;
		isActive: boolean;
		onboardingCompleted: boolean;
		status: SchemaTypes.EnrolmentStatus;
		startDate: any;
		stopDate?: any | null;
		user: { __typename?: 'FmsUser'; id: string };
		course: { __typename?: 'FmsCourse'; id: string };
	};
};

export const MinimalEnrolmentFragmentDoc = gql`
	fragment minimalEnrolment on FmsEnrolment {
		__typename
		id
		user {
			id
		}
		course {
			id
		}
		isActive
		onboardingCompleted
		status
		startDate
		stopDate
	}
`;
export const GetEnrolmentDocument = gql`
	query getEnrolment($id: ID!) {
		enrolment(id: $id) {
			...minimalEnrolment
		}
	}
	${MinimalEnrolmentFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetEnrolmentGQL extends Apollo.Query<
	GetEnrolmentQuery,
	GetEnrolmentQueryVariables
> {
	document = GetEnrolmentDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const CreateEnrolmentDocument = gql`
	mutation createEnrolment($data: NewFmsEnrolmentInput!) {
		createEnrolment(data: $data) {
			...minimalEnrolment
		}
	}
	${MinimalEnrolmentFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class CreateEnrolmentGQL extends Apollo.Mutation<
	CreateEnrolmentMutation,
	CreateEnrolmentMutationVariables
> {
	document = CreateEnrolmentDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const UpdateEnrolmentDocument = gql`
	mutation updateEnrolment($data: UpdateFmsEnrolmentInput!) {
		updateEnrolment(data: $data) {
			...minimalEnrolment
		}
	}
	${MinimalEnrolmentFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class UpdateEnrolmentGQL extends Apollo.Mutation<
	UpdateEnrolmentMutation,
	UpdateEnrolmentMutationVariables
> {
	document = UpdateEnrolmentDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
	extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class EnrolmentGQLService {
	constructor(
		private getEnrolmentGql: GetEnrolmentGQL,
		private createEnrolmentGql: CreateEnrolmentGQL,
		private updateEnrolmentGql: UpdateEnrolmentGQL
	) {}

	getEnrolment(
		variables: GetEnrolmentQueryVariables,
		options?: QueryOptionsAlone<GetEnrolmentQueryVariables>
	) {
		return this.getEnrolmentGql.fetch(variables, options);
	}

	getEnrolmentWatch(
		variables: GetEnrolmentQueryVariables,
		options?: WatchQueryOptionsAlone<GetEnrolmentQueryVariables>
	) {
		return this.getEnrolmentGql.watch(variables, options);
	}

	createEnrolment(
		variables: CreateEnrolmentMutationVariables,
		options?: MutationOptionsAlone<
			CreateEnrolmentMutation,
			CreateEnrolmentMutationVariables
		>
	) {
		return this.createEnrolmentGql.mutate(variables, options);
	}

	updateEnrolment(
		variables: UpdateEnrolmentMutationVariables,
		options?: MutationOptionsAlone<
			UpdateEnrolmentMutation,
			UpdateEnrolmentMutationVariables
		>
	) {
		return this.updateEnrolmentGql.mutate(variables, options);
	}
}
