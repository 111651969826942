import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type ChipStatus = 'default' | 'danger' | 'warning' | 'success';

@Component({
	standalone: true,
	selector: 'fms-chip',
	templateUrl: 'chip.component.html',
	styleUrls: ['chip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class ChipComponent {
	@Input() status: ChipStatus = 'default';
}
