import { createAction, props } from '@ngrx/store';

import { UserState } from './user.reducer';

export const setJwt = createAction(
	'[User] Set JWT token',
	props<Partial<UserState>>()
);

export const changeCourse = createAction(
	'[User] change Course',
	props<Pick<UserState, 'curCourseId' | 'curCourseName' | 'curCourseLogo'>>()
);

export const loadUser = createAction(
	'[User] Load User',
	props<Partial<UserState>>()
);

export const loadUserSuccess = createAction('[User] Load User Success');

export const loadUserFailure = createAction(
	'[User] Load User failure',
	props<{ error: Error }>()
);

export const updateUser = createAction(
	'[User] Update User',
	props<Partial<UserState>>()
);

export const logout = createAction('[User] Logout');
