/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';
import { DefaultMediaElementFragmentDoc } from '../media-element/media-element.service';
import { MinimalUserActionFragmentDoc } from '../user-action/user-action.service';

export type MinimalSessionComponentFragment = {
	__typename: 'FmsSessionComponent';
	id: string;
	title?: string | null;
	description?: string | null;
	componentType: SchemaTypes.SessionComponentType;
	aboveFoldContent?: string | null;
	seenAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
	favoriteAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
};

export type DefaultSessionComponentFragment = {
	__typename: 'FmsSessionComponent';
	belowFoldContent?: string | null;
	legacyUrl?: string | null;
	id: string;
	title?: string | null;
	description?: string | null;
	componentType: SchemaTypes.SessionComponentType;
	aboveFoldContent?: string | null;
	mediaElement?: {
		__typename: 'FmsMediaElement';
		id: string;
		title?: string | null;
		description?: string | null;
		mediaType: SchemaTypes.MediaType;
		duration?: number | null;
		srcProvider: SchemaTypes.FmsMediaProvider;
		srcId?: string | null;
		srcUrl?: string | null;
	} | null;
	seenAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
	favoriteAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
};

export type DetailedSessionComponentFragment = {
	__typename: 'FmsSessionComponent';
	belowFoldContent?: string | null;
	legacyUrl?: string | null;
	id: string;
	title?: string | null;
	description?: string | null;
	componentType: SchemaTypes.SessionComponentType;
	aboveFoldContent?: string | null;
	courseSession: {
		__typename: 'FmsCourseSession';
		id: string;
		title?: string | null;
		description?: string | null;
		type: SchemaTypes.CourseSessionType;
		theme: SchemaTypes.CourseSessionTheme;
		themeNumber?: number | null;
		themeSessionNumber: number;
		course?: {
			__typename?: 'FmsCourseSession';
			id: string;
			title?: string | null;
		} | null;
	};
	mediaElement?: {
		__typename: 'FmsMediaElement';
		id: string;
		title?: string | null;
		description?: string | null;
		mediaType: SchemaTypes.MediaType;
		duration?: number | null;
		srcProvider: SchemaTypes.FmsMediaProvider;
		srcId?: string | null;
		srcUrl?: string | null;
	} | null;
	seenAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
	favoriteAction?: {
		__typename: 'FmsUserAction';
		id: string;
		actionType: SchemaTypes.UserActionType;
		createdAt: any;
		updatedAt: any;
		user: { __typename?: 'FmsUser'; id: string };
		component: { __typename?: 'FmsSessionComponent'; id: string };
	} | null;
};

export type GetSessionComponentQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetSessionComponentQuery = {
	__typename?: 'Query';
	sessionComponent: {
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	};
};

export type GetAllSessionComponentsQueryVariables = SchemaTypes.Exact<{
	courseId: SchemaTypes.Scalars['ID']['input'];
	componentType?: SchemaTypes.InputMaybe<SchemaTypes.SessionComponentType>;
}>;

export type GetAllSessionComponentsQuery = {
	__typename?: 'Query';
	sessionComponents: Array<{
		__typename: 'FmsSessionComponent';
		belowFoldContent?: string | null;
		legacyUrl?: string | null;
		id: string;
		title?: string | null;
		description?: string | null;
		componentType: SchemaTypes.SessionComponentType;
		aboveFoldContent?: string | null;
		courseSession: {
			__typename: 'FmsCourseSession';
			id: string;
			title?: string | null;
			description?: string | null;
			type: SchemaTypes.CourseSessionType;
			theme: SchemaTypes.CourseSessionTheme;
			themeNumber?: number | null;
			themeSessionNumber: number;
			course?: {
				__typename?: 'FmsCourseSession';
				id: string;
				title?: string | null;
			} | null;
		};
		mediaElement?: {
			__typename: 'FmsMediaElement';
			id: string;
			title?: string | null;
			description?: string | null;
			mediaType: SchemaTypes.MediaType;
			duration?: number | null;
			srcProvider: SchemaTypes.FmsMediaProvider;
			srcId?: string | null;
			srcUrl?: string | null;
		} | null;
		seenAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
		favoriteAction?: {
			__typename: 'FmsUserAction';
			id: string;
			actionType: SchemaTypes.UserActionType;
			createdAt: any;
			updatedAt: any;
			user: { __typename?: 'FmsUser'; id: string };
			component: { __typename?: 'FmsSessionComponent'; id: string };
		} | null;
	}>;
};

export const MinimalSessionComponentFragmentDoc = gql`
	fragment minimalSessionComponent on FmsSessionComponent {
		__typename
		id
		title
		description
		componentType
		seenAction {
			...minimalUserAction
		}
		favoriteAction {
			...minimalUserAction
		}
		aboveFoldContent
	}
	${MinimalUserActionFragmentDoc}
`;
export const DefaultSessionComponentFragmentDoc = gql`
	fragment defaultSessionComponent on FmsSessionComponent {
		...minimalSessionComponent
		belowFoldContent
		legacyUrl
		mediaElement {
			...defaultMediaElement
		}
	}
	${MinimalSessionComponentFragmentDoc}
	${DefaultMediaElementFragmentDoc}
`;
export const DetailedSessionComponentFragmentDoc = gql`
	fragment detailedSessionComponent on FmsSessionComponent {
		...defaultSessionComponent
		courseSession {
			__typename
			id
			title
			description
			type
			theme
			themeNumber
			themeSessionNumber
			course {
				id
				title
			}
		}
	}
	${DefaultSessionComponentFragmentDoc}
`;
export const GetSessionComponentDocument = gql`
	query getSessionComponent($id: ID!) {
		sessionComponent(id: $id) {
			...defaultSessionComponent
		}
	}
	${DefaultSessionComponentFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetSessionComponentGQL extends Apollo.Query<
	GetSessionComponentQuery,
	GetSessionComponentQueryVariables
> {
	document = GetSessionComponentDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const GetAllSessionComponentsDocument = gql`
	query getAllSessionComponents(
		$courseId: ID!
		$componentType: SessionComponentType
	) {
		sessionComponents(courseId: $courseId, componentType: $componentType) {
			...detailedSessionComponent
		}
	}
	${DetailedSessionComponentFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetAllSessionComponentsGQL extends Apollo.Query<
	GetAllSessionComponentsQuery,
	GetAllSessionComponentsQueryVariables
> {
	document = GetAllSessionComponentsDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class SessionComponentGQLService {
	constructor(
		private getSessionComponentGql: GetSessionComponentGQL,
		private getAllSessionComponentsGql: GetAllSessionComponentsGQL
	) {}

	getSessionComponent(
		variables: GetSessionComponentQueryVariables,
		options?: QueryOptionsAlone<GetSessionComponentQueryVariables>
	) {
		return this.getSessionComponentGql.fetch(variables, options);
	}

	getSessionComponentWatch(
		variables: GetSessionComponentQueryVariables,
		options?: WatchQueryOptionsAlone<GetSessionComponentQueryVariables>
	) {
		return this.getSessionComponentGql.watch(variables, options);
	}

	getAllSessionComponents(
		variables: GetAllSessionComponentsQueryVariables,
		options?: QueryOptionsAlone<GetAllSessionComponentsQueryVariables>
	) {
		return this.getAllSessionComponentsGql.fetch(variables, options);
	}

	getAllSessionComponentsWatch(
		variables: GetAllSessionComponentsQueryVariables,
		options?: WatchQueryOptionsAlone<GetAllSessionComponentsQueryVariables>
	) {
		return this.getAllSessionComponentsGql.watch(variables, options);
	}
}
