import { Browser } from '@capacitor/browser';

import { Injectable, inject } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { User as Auth0User } from '@auth0/auth0-spa-js';

import {
	Subject,
	BehaviorSubject,
	of,
	Subscription,
	race,
	combineLatest,
	interval,
} from 'rxjs';
import { take, switchMap, tap, first, map, filter } from 'rxjs/operators';

import { select, Store, Action } from '@ngrx/store';

import { UserGQLService } from '@fms/ngx-gql-client';
import { FmsUser } from '@fms/ts-gql-types';

import * as UserActions from './+state/user/user.actions';
import * as fromUser from './+state/user/user.reducer';
import * as UserSelectors from './+state/user/user.selectors';

export interface IAuthState {
	profile: Auth0User;
	curUser?: FmsUser;
	error?: Error;
}

@Injectable({ providedIn: 'root' })
export class UserFacade {
	readonly #store = inject(Store<fromUser.UserPartialState>);
	readonly #userGql = inject(UserGQLService);
	readonly #auth0 = inject(AuthService);
	readonly userState$ = this.#store.pipe(select(UserSelectors.getUserState));
	#userLoadSubscription: Subscription;
	readonly authState$: Subject<IAuthState> = new BehaviorSubject(null);
	readonly #curUserQueryRef = this.#userGql.currentUserWatch(
		{},
		{ fetchPolicy: 'cache-and-network' }
	);
	readonly currentUser$ = this.#curUserQueryRef.valueChanges.pipe(
		map((result) => {
			console.log('currentUser$', result);
			return result.data?.currentUser;
		})
	);
	state: fromUser.UserState;
	auth0Profile: Auth0User;
	constructor() {
		this.userState$.subscribe((state) => {
			this.state = state;
		});

		this.#auth0.error$.subscribe((error) => {
			console.log('AUTH0 error:', error);
		});
		this.setupUserLoad();
		this.authState$
			.pipe(
				first(
					(authState) =>
						authState?.curUser !== null && authState?.curUser !== undefined
				)
			)
			.subscribe((authState) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { profile, curUser, error } = authState;
				const enrolment =
					curUser.enrolments.filter((enrolment) => enrolment.isActive)[0] ??
					null;
				this.loadUser({
					firstName: curUser.firstName,
					lastName: curUser.lastName,
					imageUrl: profile.picture,
					email: curUser.email,
					emailVerified: curUser.emailVerified,
					locale: profile.locale,
					userId: curUser.id,
					userSub: curUser.auth0Sub,
					auth0UpdatedAt: profile.updated_at,
					curCourseId: enrolment?.course?.id,
					curCourseName: enrolment?.course?.title,
					curCourseLogo: enrolment?.course?.imageUrl,
					curCourseEnrolmentId: enrolment?.id,
					curCourseOnboardingCompleted: enrolment?.onboardingCompleted,
					userLoaded: true,
				});
			});
	}

	setupUserLoad(): void {
		// We unsubscribe from the existing subscription if any
		this.#userLoadSubscription?.unsubscribe();
		this.#userLoadSubscription = this.#auth0.isAuthenticated$
			.pipe(
				filter((result) => result === true),
				switchMap(() =>
					this.#auth0.user$.pipe(
						tap((result) => (this.auth0Profile = result)),
						switchMap((profile) => {
							if (profile === null) {
								return combineLatest([
									this.#auth0.isAuthenticated$,
									race(this.#auth0.error$, interval(20).pipe(map(() => null))),
								]).pipe(
									take(1),
									switchMap(([_authenticated, error]) => {
										return of({ error, profile });
									})
								);
							} else {
								return this.currentUser$.pipe(
									take(1),
									switchMap((curUser) => {
										return of({
											profile,
											curUser: curUser,
											error: null,
										});
									})
								);
							}
						})
					)
				)
			)
			.subscribe(
				(result) => {
					if (result?.error) {
						const errorJson = JSON.parse(JSON.stringify(result.error));
						this.dispatch(
							UserActions.loadUserFailure(
								{ error: errorJson } // We have to help the NGRX state as it loses info when given Error object
							)
						);
					}
					this.authState$.next(result);
				},
				(error) => {
					// TODO: Add Sentry catch of error
					const errorJson = JSON.parse(JSON.stringify(error));
					this.dispatch(UserActions.loadUserFailure({ error: errorJson }));
				}
			);
	}

	dispatch(action: Action) {
		this.#store.dispatch(action);
	}

	logout() {
		this.dispatch(UserActions.logout());
	}

	loadUser(props: Partial<fromUser.UserState>) {
		this.dispatch(UserActions.loadUser(props));
	}

	updateState(props: Partial<fromUser.UserState>) {
		this.dispatch(UserActions.updateUser(props));
	}

	/**
	 * Initiate Capacitor app (iOS/Android) login. Only available on those platforms
	 */
	initiateCapacitorLogin() {
		this.#auth0
			.loginWithRedirect({
				openUrl: (url) => Browser.open({ url, windowName: '_self' }),
			})
			.subscribe();
	}

	/**
	 * Initiate app (iOS/Android) login. Only available on those platforms
	 */
	initiateCapacitorLogout() {
		// Use the SDK to build the logout URL
		this.#auth0
			.logout({
				openUrl: (url) => Browser.open({ url }),
			})
			.subscribe();
	}

	refetchUserFromBackend() {
		this.#curUserQueryRef.refetch();
	}
}
