/* eslint-disable: interface-over-type-literal */
import { Injectable } from '@angular/core';

import * as ApolloCore from '@apollo/client/core';

import { gql } from 'apollo-angular';
import * as Apollo from 'apollo-angular';

import * as SchemaTypes from '@fms/ts-gql-types';

export type MinimalUserFragment = {
	__typename: 'FmsUser';
	id: string;
	firstName?: string | null;
	lastName?: string | null;
	email: string;
	emailVerified: boolean;
};

export type DefaultUserFragment = {
	__typename: 'FmsUser';
	phone?: string | null;
	createdAt: any;
	updatedAt: any;
	id: string;
	firstName?: string | null;
	lastName?: string | null;
	email: string;
	emailVerified: boolean;
	createdByUser?: {
		__typename: 'FmsUser';
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		emailVerified: boolean;
	} | null;
	enrolments?: Array<{
		__typename?: 'FmsEnrolment';
		id: string;
		isActive: boolean;
		onboardingCompleted: boolean;
		status: SchemaTypes.EnrolmentStatus;
		startDate: any;
		stopDate?: any | null;
		course: {
			__typename?: 'FmsCourse';
			id: string;
			title?: string | null;
			description?: string | null;
			imageUrl?: string | null;
		};
	}> | null;
};

export type GetUserQueryVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetUserQuery = {
	__typename?: 'Query';
	user: {
		__typename: 'FmsUser';
		phone?: string | null;
		createdAt: any;
		updatedAt: any;
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		emailVerified: boolean;
		createdByUser?: {
			__typename: 'FmsUser';
			id: string;
			firstName?: string | null;
			lastName?: string | null;
			email: string;
			emailVerified: boolean;
		} | null;
		enrolments?: Array<{
			__typename?: 'FmsEnrolment';
			id: string;
			isActive: boolean;
			onboardingCompleted: boolean;
			status: SchemaTypes.EnrolmentStatus;
			startDate: any;
			stopDate?: any | null;
			course: {
				__typename?: 'FmsCourse';
				id: string;
				title?: string | null;
				description?: string | null;
				imageUrl?: string | null;
			};
		}> | null;
	};
};

export type CreateUserMutationVariables = SchemaTypes.Exact<{
	data: SchemaTypes.NewFmsUserInput;
}>;

export type CreateUserMutation = {
	__typename?: 'Mutation';
	createUser: {
		__typename: 'FmsUser';
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		emailVerified: boolean;
	};
};

export type UpdateUserMutationVariables = SchemaTypes.Exact<{
	data: SchemaTypes.UpdateFmsUserInput;
}>;

export type UpdateUserMutation = {
	__typename?: 'Mutation';
	updateUser?: {
		__typename: 'FmsUser';
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		emailVerified: boolean;
	} | null;
};

export type RemoveUserMutationVariables = SchemaTypes.Exact<{
	id: SchemaTypes.Scalars['ID']['input'];
}>;

export type RemoveUserMutation = {
	__typename?: 'Mutation';
	removeUser: boolean;
};

export type CurUserFragment = {
	__typename: 'FmsUser';
	emailVerified: boolean;
	auth0Sub?: string | null;
	phone?: string | null;
	createdAt: any;
	updatedAt: any;
	id: string;
	firstName?: string | null;
	lastName?: string | null;
	email: string;
	createdByUser?: {
		__typename: 'FmsUser';
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		emailVerified: boolean;
	} | null;
	enrolments?: Array<{
		__typename?: 'FmsEnrolment';
		id: string;
		isActive: boolean;
		onboardingCompleted: boolean;
		status: SchemaTypes.EnrolmentStatus;
		startDate: any;
		stopDate?: any | null;
		course: {
			__typename?: 'FmsCourse';
			id: string;
			title?: string | null;
			description?: string | null;
			imageUrl?: string | null;
		};
	}> | null;
};

export type CurrentUserQueryVariables = SchemaTypes.Exact<{
	[key: string]: never;
}>;

export type CurrentUserQuery = {
	__typename?: 'Query';
	currentUser?: {
		__typename: 'FmsUser';
		emailVerified: boolean;
		auth0Sub?: string | null;
		phone?: string | null;
		createdAt: any;
		updatedAt: any;
		id: string;
		firstName?: string | null;
		lastName?: string | null;
		email: string;
		createdByUser?: {
			__typename: 'FmsUser';
			id: string;
			firstName?: string | null;
			lastName?: string | null;
			email: string;
			emailVerified: boolean;
		} | null;
		enrolments?: Array<{
			__typename?: 'FmsEnrolment';
			id: string;
			isActive: boolean;
			onboardingCompleted: boolean;
			status: SchemaTypes.EnrolmentStatus;
			startDate: any;
			stopDate?: any | null;
			course: {
				__typename?: 'FmsCourse';
				id: string;
				title?: string | null;
				description?: string | null;
				imageUrl?: string | null;
			};
		}> | null;
	} | null;
};

export const MinimalUserFragmentDoc = gql`
	fragment minimalUser on FmsUser {
		__typename
		id
		firstName
		lastName
		email
		emailVerified
	}
`;
export const DefaultUserFragmentDoc = gql`
	fragment defaultUser on FmsUser {
		...minimalUser
		phone
		createdAt
		createdByUser {
			...minimalUser
		}
		updatedAt
		enrolments {
			id
			isActive
			onboardingCompleted
			status
			startDate
			stopDate
			course {
				id
				title
				description
				imageUrl
			}
		}
	}
	${MinimalUserFragmentDoc}
`;
export const CurUserFragmentDoc = gql`
	fragment curUser on FmsUser {
		...defaultUser
		emailVerified
		auth0Sub
	}
	${DefaultUserFragmentDoc}
`;
export const GetUserDocument = gql`
	query getUser($id: ID!) {
		user(id: $id) {
			...defaultUser
		}
	}
	${DefaultUserFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class GetUserGQL extends Apollo.Query<
	GetUserQuery,
	GetUserQueryVariables
> {
	document = GetUserDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const CreateUserDocument = gql`
	mutation createUser($data: NewFmsUserInput!) {
		createUser(data: $data) {
			...minimalUser
		}
	}
	${MinimalUserFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class CreateUserGQL extends Apollo.Mutation<
	CreateUserMutation,
	CreateUserMutationVariables
> {
	document = CreateUserDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const UpdateUserDocument = gql`
	mutation updateUser($data: UpdateFmsUserInput!) {
		updateUser(data: $data) {
			...minimalUser
		}
	}
	${MinimalUserFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class UpdateUserGQL extends Apollo.Mutation<
	UpdateUserMutation,
	UpdateUserMutationVariables
> {
	document = UpdateUserDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const RemoveUserDocument = gql`
	mutation removeUser($id: ID!) {
		removeUser(id: $id)
	}
`;

@Injectable({
	providedIn: 'root',
})
export class RemoveUserGQL extends Apollo.Mutation<
	RemoveUserMutation,
	RemoveUserMutationVariables
> {
	document = RemoveUserDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}
export const CurrentUserDocument = gql`
	query currentUser {
		currentUser {
			...curUser
		}
	}
	${CurUserFragmentDoc}
`;

@Injectable({
	providedIn: 'root',
})
export class CurrentUserGQL extends Apollo.Query<
	CurrentUserQuery,
	CurrentUserQueryVariables
> {
	document = CurrentUserDocument;

	constructor(apollo: Apollo.Apollo) {
		super(apollo);
	}
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
	extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

interface QueryOptionsAlone<V>
	extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

interface MutationOptionsAlone<T, V>
	extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

@Injectable({ providedIn: 'root' })
export class UserGQLService {
	constructor(
		private getUserGql: GetUserGQL,
		private createUserGql: CreateUserGQL,
		private updateUserGql: UpdateUserGQL,
		private removeUserGql: RemoveUserGQL,
		private currentUserGql: CurrentUserGQL
	) {}

	getUser(
		variables: GetUserQueryVariables,
		options?: QueryOptionsAlone<GetUserQueryVariables>
	) {
		return this.getUserGql.fetch(variables, options);
	}

	getUserWatch(
		variables: GetUserQueryVariables,
		options?: WatchQueryOptionsAlone<GetUserQueryVariables>
	) {
		return this.getUserGql.watch(variables, options);
	}

	createUser(
		variables: CreateUserMutationVariables,
		options?: MutationOptionsAlone<
			CreateUserMutation,
			CreateUserMutationVariables
		>
	) {
		return this.createUserGql.mutate(variables, options);
	}

	updateUser(
		variables: UpdateUserMutationVariables,
		options?: MutationOptionsAlone<
			UpdateUserMutation,
			UpdateUserMutationVariables
		>
	) {
		return this.updateUserGql.mutate(variables, options);
	}

	removeUser(
		variables: RemoveUserMutationVariables,
		options?: MutationOptionsAlone<
			RemoveUserMutation,
			RemoveUserMutationVariables
		>
	) {
		return this.removeUserGql.mutate(variables, options);
	}

	currentUser(
		variables?: CurrentUserQueryVariables,
		options?: QueryOptionsAlone<CurrentUserQueryVariables>
	) {
		return this.currentUserGql.fetch(variables, options);
	}

	currentUserWatch(
		variables?: CurrentUserQueryVariables,
		options?: WatchQueryOptionsAlone<CurrentUserQueryVariables>
	) {
		return this.currentUserGql.watch(variables, options);
	}
}
