import { IEnvironment } from './env.interface';

export const environment: IEnvironment = {
	production: true,
	envName: 'prod',
	apiUrl: 'https://api.åbenogrolig.dk',
	graphQlRoute: '/graphql',
	auth0: {
		domain: 'fondenmentalsundhed.eu.auth0.com',
		clientId: 'u8OxQY7HVc82NwLBszUd4Jo99qjkLU6f', // Åben og Rolig Online
		redirectUri: 'https://kursus.xn--benogrolig-05a.dk/auth-callback',
		authorizeTimeoutInSeconds: 60,
		cacheLocation: 'memory',
	},
	cookieDomain: 'kursus.åbenogrolig.dk',
	sentry: {
		enabled: true,
		environment: 'production',
		release: '2.0.0-prod',
		dsn: 'https://fb038eff28ba48bf8ba4181c5c094344@o334704.ingest.sentry.io/5714874',
		showErrorDialog: false,
	},
};
